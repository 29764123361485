import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "src/Environment";
import {
 FETCH_PAYMENT_DETAILS_START,
 FETCH_SINGLE_TOKEN_START,
 FETCH_TOKEN_LIST_START,
 SAVE_TOKEN_CONTRACT_ADDR_START,
 SAVE_TOKEN_PAYMENT_AUTO_START,
 SAVE_TOKEN_PAYMENT_MANUAL_START,
 SAVE_TOKEN_START,
 TEMP_SAVE_TOKEN_START,
} from "../actions/ActionConstant";
import {
 getSuccessNotificationMessage,
 getErrorNotificationMessage,
} from "src/components/ToastNotification";
import { fetchPaymentDetailsFailure, fetchPaymentDetailsSuccess, fetchSingleTokenFailure, fetchSingleTokenSuccess, fetchTokenListFailure, fetchTokenListSuccess, saveTokenContractAddrFailure, saveTokenContractAddrSuccess, saveTokenFailure, saveTokenPaymentAutoFailure, saveTokenPaymentAutoSuccess, saveTokenPaymentManualFailure, saveTokenPaymentManualSuccess, saveTokenSuccess, tempSaveTokenFailure, tempSaveTokenSuccess } from "../actions/TokenAction";


function* saveTokenApi() {
 try {
  const inputData = yield select((state) => state.token.saveToken.inputData);
  const response = yield api.postMethod(
   "crypto_tokens_save",
   inputData
  );
  if (response.data.success) {
   yield put(saveTokenSuccess(response.data.data));
   yield call(getSuccessNotificationMessage, response.data.message);;
  } else {
   yield put(saveTokenFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(saveTokenFailure(error));
  yield call(getErrorNotificationMessage, error.response.data.error);
 }
}

function* tempSaveTokenApi() {
 try {
  const inputData = yield select((state) => state.token.tempSaveToken.inputData);
  const response = yield api.postMethod(
   "crypto_tokens_save",
   inputData
  );
  if (response.data.success) {
   yield put(tempSaveTokenSuccess(response.data.data));
   yield call(getSuccessNotificationMessage, response.data.message);
  } else {
   yield put(tempSaveTokenFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(tempSaveTokenFailure(error));
  yield call(getErrorNotificationMessage, error.response.data.error);
 }
}

function* saveTokenPayAutoAPI() {
 try {
  const inputData = yield select((state) => state.token.saveTokenPayAuto.inputData);
  const response = yield api.postMethod(
   "crypto_tokens_payments_save",
   inputData
  );
  if (response.data.success) {
   yield put(saveTokenPaymentAutoSuccess(response.data.data));
   yield call(getSuccessNotificationMessage, response.data.message);
   if (response.data.data.payment_type == "auto")
    window.location.assign(`/tokens/deploy-token/${response.data.data.crypto_token_unique_id}`);
   else {
    yield call(getSuccessNotificationMessage, "Admin will create your token and send details in email..");
    setTimeout(() => {
     window.location.assign('/tokens/tokens-list');
    }, 2000);

   }

  } else {
   yield put(saveTokenPaymentAutoFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(saveTokenPaymentAutoFailure(error));
  yield call(getErrorNotificationMessage, error.response.data.error);
 }
}

function* saveTokenPayManualAPI() {
 try {
  const inputData = yield select((state) => state.token.saveTokenPayManual.inputData);
  const response = yield api.postMethod(
   "crypto_tokens_save",
   inputData
  );
  if (response.data.success) {
   yield put(saveTokenPaymentManualSuccess(response.data.data));
   yield call(getSuccessNotificationMessage, response.data.message);
   window.location.assign('/tokens/tokens-list');
  } else {
   yield put(saveTokenPaymentManualFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(saveTokenPaymentManualFailure(error));
  yield call(getErrorNotificationMessage, error.response.data.error);
 }
}

function* saveTokenContractAPI() {
 try {
  const inputData = yield select((state) => state.token.saveTokenContract.inputData);
  const response = yield api.postMethod(
   "crypto_tokens_contract_address_update",
   inputData
  );
  if (response.data.success) {
   yield put(saveTokenContractAddrSuccess(response.data.data));
   yield call(getSuccessNotificationMessage, response.data.message);
   window.location.assign('/tokens/tokens-list');
  } else {
   yield put(saveTokenContractAddrFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(saveTokenContractAddrFailure(error));
  yield call(getErrorNotificationMessage, error.response.data.error);
 }
}

function* fetchTokenListAPI() {
 try {
  const response = yield api.postMethod("crypto_tokens_index");
  if (response.data.success) {
   yield put(fetchTokenListSuccess(response.data.data));
  } else {
   yield put(fetchTokenListFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(fetchTokenListFailure(error));
  // window.location.assign('/tokens/create-token');
  yield call(getErrorNotificationMessage, error.message);
 }
}


function* fetchSingleTokenAPI() {
 try {
  const inputData = yield select((state) => state.token.tokenDetails.inputData);
  const response = yield api.postMethod("crypto_tokens_view", inputData);
  if (response.data.success) {
   yield put(fetchSingleTokenSuccess(response.data.data));
  } else {
   yield put(fetchSingleTokenFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
   setTimeout(() => {
    window.location.assign('/tokens/tokens-list');
   }, 2000);

  }
 } catch (error) {
  yield put(fetchSingleTokenFailure(error));
  yield call(getErrorNotificationMessage, error.message);
  window.location.assign('/tokens/tokens-list');
 }
}

function* fetchPaymentInfoAPI() {
 try {
  const response = yield api.postMethod("payment_information");
  if (response.data.success) {
   yield put(fetchPaymentDetailsSuccess(response.data.data));
  } else {
   yield put(fetchPaymentDetailsFailure(response.data.error));
   yield call(getErrorNotificationMessage, response.data.error);
  }
 } catch (error) {
  yield put(fetchPaymentDetailsFailure(error));
  // window.location.assign('/tokens/create-token');
  yield call(getErrorNotificationMessage, error.message);;
 }
}

export default function* pageSaga() {
 yield all([
  yield takeLatest(SAVE_TOKEN_START, saveTokenApi),
  yield takeLatest(FETCH_SINGLE_TOKEN_START, fetchSingleTokenAPI),
  yield takeLatest(FETCH_TOKEN_LIST_START, fetchTokenListAPI),
  yield takeLatest(SAVE_TOKEN_CONTRACT_ADDR_START, saveTokenContractAPI),
  yield takeLatest(SAVE_TOKEN_PAYMENT_MANUAL_START, saveTokenPayManualAPI),
  yield takeLatest(SAVE_TOKEN_PAYMENT_AUTO_START, saveTokenPayAutoAPI),
  yield takeLatest(FETCH_PAYMENT_DETAILS_START, fetchPaymentInfoAPI),
  yield takeLatest(TEMP_SAVE_TOKEN_START, tempSaveTokenApi),
 ]);
}

import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useCurrentRole from 'src/hooks/useCurrentRole';
import { Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Navigate to="/" replace />
    );
  }

  return <>{children}</>;
}

import Cookies from 'universal-cookie';

const useCookies = () => {

  const cookies = new Cookies();

  const setCookie = (cookieName , value) => {
    cookies.set(cookieName ,value , {sameSite : true , path: '/'} )
  }

  const getCookie = (cookieName) => {
    return cookies.get(cookieName)
  }

  cookies.addChangeListener((res) => console.log(res))

  return {setCookie , getCookie}
}

export default useCookies
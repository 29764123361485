import axios from "axios";
import { apiConstants } from "./Constant/constants";
import Cookies from "universal-cookie";
import { isValidToken, decodedToken } from "../src/utils/jwt";
import { getErrorNotificationMessage } from "src/components/ToastNotification";

const apiUrl = "https://cms-nftlaunchpad.blockstall.com/api/"; // Production Mode

const cookies = new Cookies();

let isRefreshing = false;

let failedQueue = [];

const axiosInstance = axios.create({
  apiUrl,
});

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

const errorHandler = (error) => {
  return Promise.reject(error);
};

const requestHandler = (request) => {
  let accessToken = cookies.get("accessToken");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        let accessToken = cookies.get("accessToken");

        const tokenData = decodedToken(accessToken);

        let userId = tokenData.user_id;

        formData.append("token", accessToken);

        formData.append("id", userId);

        axios
          .post(`${apiUrl}${tokenData.role}/refresh_token_update`, formData)
          .then(({ data }) => {
            cookies.set("accessToken", data.data.jwt_token, {
              sameSite: true,
              path: "/",
            });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.data.jwt_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.data.jwt_token;
            processQueue(null, data.data.jwt_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            console.log(err);
            cookies.remove("accessToken", { sameSite: true, path: "/" });
            window.location.replace("/auth/logout");
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

const Environment = {
  postMethod: async (action, object) => {
    // let userId =
    //   localStorage.getItem("userId") !== "" &&
    //   localStorage.getItem("userId") !== null &&
    //   localStorage.getItem("userId") !== undefined
    //     ? localStorage.getItem("userId")
    //     : "";

    // let accessToken =
    //   localStorage.getItem("accessToken") !== "" &&
    //   localStorage.getItem("accessToken") !== null &&
    //   localStorage.getItem("accessToken") !== undefined
    //     ? localStorage.getItem("accessToken")
    //     : "";

    const url = apiUrl + action;

    let formData = new FormData();

    let accessToken = cookies.get("accessToken");

    const tokenData = decodedToken(accessToken);

    let userId = tokenData.user_id;

    formData.append("id", userId);
    formData.append("token", accessToken);

    var socialLoginUser = 0;

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);

      if (key === "social_unique_id") {
        socialLoginUser = 1;
      }
    }

    // By Default added device type and login type in future use
    if (!socialLoginUser) {
      formData.append("login_by", apiConstants.LOGIN_BY);
    }

    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);

    return await axiosInstance.post(url, formData);

    // Progress bar
    // {
    //   onUploadProgress: (ProgressEvent) => {
    //     console.log({
    //       loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
    //     });
    //   },
    // }
  },

  getMethod: async (action, object) => {
    let userId =
      localStorage.getItem("userId") !== "" &&
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";
    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
      localStorage.getItem("accessToken") !== null &&
      localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    const url = apiUrl + action;

    let formData = new FormData();

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);
    }

    // By Default added device type and login type in future use

    formData.append("login_by", apiConstants.LOGIN_BY);
    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);

    return await axios.get(url, formData);
  },

  /*methods(action) {

        const url = apiUrl+'/api/'+action;

        return {
            getOne: ({ id }) => axios.get(`${url}/${id}`),
            getAll: (toGet) => axios.post(url, toGet),
            update: (toUpdate) =>  axios.put(url,toUpdate),
            create: (toCreate) =>  axios.put(url,toCreate),
            delete: ({ id }) =>  axios.delete(`${url}/${id}`)
        }
    }*/
};

export default Environment;

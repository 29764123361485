import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";

// components/
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
        { path: "logout", element: <Logout /> }
      ],
    },

    // Dashboard Routes
    {
      path: "creator",
      element: (
        <RoleBasedGuard accessibleRoles={["creator"]}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        {
          element: <Navigate to={"/creator/dashboard"} replace />,
          index: true,
        },
        { path: "dashboard", element: <GeneralApp /> },
        {
          path: "contract",
          children: [
            {
              element: <Navigate to="/creator/contract/create" replace />,
              index: true,
            },
            { path: "create", element: <CreateContract /> },
            { path: "list", element: <ContractList /> },
            { path: "edit/:contract_unique_id", element: <EditContract /> },
            { path: "deploy/:contract_unique_id", element: <DeployContract /> },
            { path: "generated/:contract_unique_id", element: <CreatorImageList /> },
            { path: "upload/:contract_unique_id", element: <UploadNFT /> },
            { path: "view/:contract_unique_id", element: <ViewContract /> },
            {
              path: "image",
              children: [
                { path: "view/:merge_image_unique_id", element: <ViewGeneratedImage /> },
                { path: "edit/:merge_image_unique_id", element: <EditGeneratedImage /> },
                { path: "create/:contract_unique_id", element: <AddGeneratedImage /> },
                { path: "*", element: <Navigate to="/404" replace /> },
              ],
            },
          ],
        },
        { path: "profile", element: <CreatorAccount /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "contracts", element: <Projects /> },
        { path: "single-contract/:contract_unique_id", element: <SingleProject /> },
        { path: "staking", element: <StakingIndex /> },
        { path: "add_project", element: <Contact /> },
        { path: "page/:unique_id", element: <StaticPage /> },
      ],
    },

    //user routes
    {
      path: "account",
      element: (
        <RoleBasedGuard accessibleRoles={["user"]}>
          <MainLayout />
        </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to="/account/profile" replace />, index: true },
        { path: "profile", element: <UserProfile /> },
        { path: "edit_profile", element: <EditProfile /> },
        { path: "wallet", element: <UserWallet /> },
        { path: "minted_contracts", element: <InvestedProjects /> },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATIONExternal
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Projects = Loadable(lazy(() => import("../pages/Projects")));
const SingleProject = Loadable(lazy(() => import("../pages/SingleProject")));
const StakingIndex = Loadable(lazy(() => import("../pages/staking")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

//user
const UserProfile = Loadable(lazy(() => import("../pages/user/UserProfile")));
const EditProfile = Loadable(lazy(() => import("../pages/user/EditProfile")));
const UserWallet = Loadable(lazy(() => import("../pages/user/userWallet")));
const InvestedProjects = Loadable(
  lazy(() => import("../pages/user/investedProjects"))
);

//contract
const CreateContract = Loadable(
  lazy(() => import("../pages/creator/contract/CreateContract"))
);
const EditContract = Loadable(
  lazy(() => import("../pages/creator/contract/EditContract"))
);
const ContractList = Loadable(
  lazy(() => import("../pages/creator/contract/ContractList"))
);
const DeployContract = Loadable(
  lazy(() => import("../pages/creator/contract/DeployContract"))
);
const CreatorImageList = Loadable(
  lazy(() => import("../pages/creator/contract/CreatorImageList"))
);
const UploadNFT = Loadable(
  lazy(() => import("../pages/creator/contract/uploadnft/UploadNFT"))
);
const ViewContract = Loadable(
  lazy(() => import("../pages/creator/contract/ViewContract"))
);
//image
const ViewGeneratedImage = Loadable(
  lazy(() =>
    import("../pages/creator/contract/generatedImage/ViewGeneratedImage")
  )
);
const EditGeneratedImage = Loadable(
  lazy(() =>
    import("../pages/creator/contract/generatedImage/EditGeneratedImage")
  )
);
const AddGeneratedImage = Loadable(
  lazy(() =>
    import("../pages/creator/contract/generatedImage/AddGeneratedImage")
  )
);

//creator
const CreatorAccount = Loadable(lazy(() => import("../pages/creator/account")));
const Logout = Loadable(lazy(() => import("../pages/auth/Logout")));

//stati page
const StaticPage = Loadable(lazy(() => import("../pages/StaticPage")));

import {
  FETCH_CREATOR_CONTRACT_LIST_START,
  FETCH_CREATOR_CONTRACT_LIST_SUCCESS,
  FETCH_CREATOR_CONTRACT_LIST_FAILURE,
  FETCH_CREATOR_SINGLE_CONTRACT_START,
  FETCH_CREATOR_SINGLE_CONTRACT_SUCCESS,
  FETCH_CREATOR_SINGLE_CONTRACT_FAILURE,
  CREATOR_CONTRACT_SAVE_START,
  CREATOR_CONTRACT_SAVE_SUCCESS,
  CREATOR_CONTRACT_SAVE_FAILURE,
  CREATOR_CONTRACT_DELETE_START,
  CREATOR_CONTRACT_DELETE_SUCCESS,
  CREATOR_CONTRACT_DELETE_FAILURE,
  UPDATE_CONTRACT_DEPLOY_ACCESS_START,
  UPDATE_CONTRACT_DEPLOY_ACCESS_SUCCESS,
  UPDATE_CONTRACT_DEPLOY_ACCESS_FAILURE,
  DEPLOY_CONTRACT_SAVE_START,
  DEPLOY_CONTRACT_SAVE_SUCCESS,
  DEPLOY_CONTRACT_SAVE_FAILURE,
  FETCH_WHITELISTED_WALLET_LIST_START,
  FETCH_WHITELISTED_WALLET_LIST_SUCCESS,
  FETCH_WHITELISTED_WALLET_LIST_FAILURE,
  SAVE_WHITELISTED_WALLET_START,
  SAVE_WHITELISTED_WALLET_SUCCESS,
  SAVE_WHITELISTED_WALLET_FAILURE,
  SET_MINT_FEE_UPDATE_START,
  SET_MINT_FEE_UPDATE_SUCCESS,
  SET_MINT_FEE_UPDATE_FAILURE,
} from "./ActionConstant";

export function fetchCreatorContractListStart(data) {
  return {
    type: FETCH_CREATOR_CONTRACT_LIST_START,
    data,
  };
}

export function fetchCreatorContractListSuccess(data) {
  return {
    type: FETCH_CREATOR_CONTRACT_LIST_SUCCESS,
    data,
  };
}

export function fetchCreatorContractListFailure(error) {
  return {
    type: FETCH_CREATOR_CONTRACT_LIST_FAILURE,
    error,
  };
}

export function fetchCreatorSingleContractStart(data) {
  return {
    type: FETCH_CREATOR_SINGLE_CONTRACT_START,
    data,
  };
}

export function fetchCreatorSingleContractSuccess(data) {
  return {
    type: FETCH_CREATOR_SINGLE_CONTRACT_SUCCESS,
    data,
  };
}

export function fetchCreatorSingleContractFailure(error) {
  return {
    type: FETCH_CREATOR_SINGLE_CONTRACT_FAILURE,
    error,
  };
}

export function creatorContractSaveStart(data) {
  return {
    type: CREATOR_CONTRACT_SAVE_START,
    data,
  };
}

export function creatorContractSaveSuccess(data) {
  return {
    type: CREATOR_CONTRACT_SAVE_SUCCESS,
    data,
  };
}

export function creatorContractSaveFailure(error) {
  return {
    type: CREATOR_CONTRACT_SAVE_FAILURE,
    error,
  };
}

export function creatorContractDeleteStart(data) {
  return {
    type: CREATOR_CONTRACT_DELETE_START,
    data,
  };
}

export function creatorContractDeleteSuccess(data) {
  return {
    type: CREATOR_CONTRACT_DELETE_SUCCESS,
    data,
  };
}

export function creatorContractDeleteFailure(error) {
  return {
    type: CREATOR_CONTRACT_DELETE_FAILURE,
    error,
  };
}

export function updateContractDeployAccessStart(data) {
  return {
    type: UPDATE_CONTRACT_DEPLOY_ACCESS_START,
    data,
  };
}

export function updateContractDeployAccessSuccess(data) {
  return {
    type: UPDATE_CONTRACT_DEPLOY_ACCESS_SUCCESS,
    data,
  };
}

export function updateContractDeployAccessFailure(error) {
  return {
    type: UPDATE_CONTRACT_DEPLOY_ACCESS_FAILURE,
    error,
  };
}

export function deployContractSaveStart(data) {
  return {
    type: DEPLOY_CONTRACT_SAVE_START,
    data,
  };
}

export function deployContractSaveSuccess(data) {
  return {
    type: DEPLOY_CONTRACT_SAVE_SUCCESS,
    data,
  };
}

export function deployContractSaveFailure(error) {
  return {
    type: DEPLOY_CONTRACT_SAVE_FAILURE,
    error,
  };
}

export function fetchWhitelistedWalletListStart(data) {
  return {
    type: FETCH_WHITELISTED_WALLET_LIST_START,
    data,
  }
}

export function fetchWhitelistedWalletListSuccess(data) {
  return {
    type: FETCH_WHITELISTED_WALLET_LIST_SUCCESS,
    data,
  }
}

export function fetchWhitelistedWalletListFailure(error) {
  return {
    type: FETCH_WHITELISTED_WALLET_LIST_FAILURE,
    error,
  }
}

export function saveWhitelistedWalletStart(data) {
  return {
    type: SAVE_WHITELISTED_WALLET_START,
    data,
  }
}

export function saveWhitelistedWalletSuccess(data) {
  return {
    type: SAVE_WHITELISTED_WALLET_SUCCESS,
    data,
  }
}

export function saveWhitelistedWalletFailure(error) {
  return {
    type: SAVE_WHITELISTED_WALLET_FAILURE,
    error,
  }
}

export function setMintFeeUpdateStart(data) {
  return {
    type: SET_MINT_FEE_UPDATE_START,
    data,
  };
}

export function setMintFeeUpdateSuccess(data) {
  return {
    type: SET_MINT_FEE_UPDATE_SUCCESS,
    data,
  };
}

export function setMintFeeUpdateFailure(error) {
  return {
    type: SET_MINT_FEE_UPDATE_FAILURE,
    error,
  };
}
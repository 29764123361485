import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { MetaMask as injected } from "./connectors";
import { useDispatch } from "react-redux";
import {
  userAccountChangeStart,
} from "src/store/actions/UserAction";
import { useNavigate } from "react-router-dom";
import useCookies from "src/hooks/useCookies";
import { decodedToken } from "src/utils/jwt";
import configuration from "react-global-configuration";

export function useEagerConnect(inital_connect) {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (inital_connect == "true") {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      });
    }
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active && inital_connect == "true") {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress) {
  const { active, error, activate, deactivate } = useWeb3React();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCookie } = useCookies();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum !== undefined) {
      const handleConnect = () => {
        // console.log("Handling 'connect' event")
        if (localStorage.getItem("inital_connect") === "true") {
          activate(injected);
          console.log("account connect");
        }
      };
      const handleChainChanged = (chainId) => {
        // console.log("Handling 'chainChanged' event with payload", chainId)
        // activate(injected)
      };
      const handleAccountsChanged = (accounts) => {
        const accessToken = getCookie("accessToken");
        const tokenData = decodedToken(accessToken);

        if (accounts.length > 0) {
          activate(injected);
          if (accessToken && tokenData.role === "user") {
            dispatch(userAccountChangeStart({ wallet_address: accounts }));
          }
        } else {
          if (accessToken && tokenData.role === "user") {
            navigate("/auth/logout");
          }
        }
      };
      const handleNetworkChanged = (networkId) => {
        // console.log("Handling 'networkChanged' event with payload", networkId)
        const netID = configuration.get("configData.network_id")
          ? Number(configuration.get("configData.network_id"))
          : 56;
        if (netID === Number(networkId)) {
          activate(injected);
        } else {
          navigate("/auth/logout");
        }
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}

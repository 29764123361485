import {
  FETCH_LIST_OF_NFT_START,
  FETCH_LIST_OF_NFT_SUCCESS,
  FETCH_LIST_OF_NFT_FAILURE,
  FETCH_SINGLE_NFT_IMAGE_START,
  FETCH_SINGLE_NFT_IMAGE_SUCCESS,
  FETCH_SINGLE_NFT_IMAGE_FAILURE,
  FETCH_SINGLE_NFT_PROPERTIES_SUCCESS,
  FETCH_SINGLE_NFT_PROPERTIES_FAILURE,
  FETCH_SINGLE_NFT_PROPERTIES_START,
  FETCH_LIST_NFT_PROPERTIES_START,
  FETCH_LIST_NFT_PROPERTIES_SUCCESS,
  FETCH_LIST_NFT_PROPERTIES_FAILURE,
  DELETE_SINGLE_NFT_START,
  DELETE_SINGLE_NFT_SUCCESS,
  DELETE_SINGLE_NFT_FAILURE,
  UPLOAD_NFT_FILES_START,
  UPLOAD_NFT_FILES_SUCCESS,
  UPLOAD_NFT_FILES_FAILURE,
  SEARCH_NFT_IMAGE_START,
  SEARCH_NFT_IMAGE_SUCCESS,
  SEARCH_NFT_IMAGE_FAILURE,
  PAGINATE_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_SUCCESS,
  SORT_LIST_OF_NFT_FAILURE,
  EDIT_NFT_DETAILS,
  UPDATE_NFT_DETAILS_START,
  UPDATE_NFT_DETAILS_SUCCESS,
  UPDATE_NFT_DETAILS_FAILURE,
  ADD_NFT_IMAGE_START,
  ADD_NFT_IMAGE_SUCCESS,
  ADD_NFT_IMAGE_FAILURE,
  FETCH_NFT_IMAGES_LIST_AFTER_DELETE,
  USER_MINT_NFT_IMAGES_START,
  USER_MINT_NFT_IMAGES_SUCCESS,
  USER_MINT_NFT_IMAGES_FAILURE,
  USER_MINT_NFT_IMAGES_CLEAR_START,
  USER_UPDATE_NFT_IMAGE_MINT_START,
  USER_UPDATE_NFT_IMAGE_MINT_SUCCESS,
  USER_UPDATE_NFT_IMAGE_MINT_FAILURE,
} from './ActionConstant'

export function fetchListofNFTStart(data) {
  return {
    type: FETCH_LIST_OF_NFT_START,
    data,
  };
}

export function fetchListofNFTSuccess(data) {
  return {
    type: FETCH_LIST_OF_NFT_SUCCESS,
    data,
  };
}

export function fetchListofNFTFailure(error) {
  return {
    type: FETCH_LIST_OF_NFT_FAILURE,
    error,
  };
}

export function fetchSingleNFTStart(data) {
  return {
    type: FETCH_SINGLE_NFT_IMAGE_START,
    data,
  };
}

export function fetchSingleNFTSuccess(data) {
  return {
    type: FETCH_SINGLE_NFT_IMAGE_SUCCESS,
    data,
  };
}

export function fetchSingleNFTFailure(error) {
  return {
    type: FETCH_SINGLE_NFT_IMAGE_FAILURE,
    error,
  };
}

export function fetchSingleNFTPropertiesStart(data) {
  return {
    type: FETCH_SINGLE_NFT_PROPERTIES_START,
    data,
  };
}

export function fetchSingleNFTPropertiesSuccess(data) {
  return {
    type: FETCH_SINGLE_NFT_PROPERTIES_SUCCESS,
    data,
  };
}

export function fetchSingleNFTPropertiesFailure(error) {
  return {
    type: FETCH_SINGLE_NFT_PROPERTIES_FAILURE,
    error,
  };
}

export function fetchListNFTPropertiesStart(data) {
  return {
    type: FETCH_LIST_NFT_PROPERTIES_START,
    data,
  };
}

export function fetchListNFTPropertiesSuccess(data) {
  return {
    type: FETCH_LIST_NFT_PROPERTIES_SUCCESS,
    data,
  };
}

export function fetchListNFTPropertiesFailure(error) {
  return {
    type: FETCH_LIST_NFT_PROPERTIES_FAILURE,
    error,
  };
}

export function deleteSingleNFTStart(data) {
  return {
    type: DELETE_SINGLE_NFT_START,
    data,
  };
}

export function deleteSingleNFTSuccess(data) {
  return {
    type: DELETE_SINGLE_NFT_SUCCESS,
    data,
  };
}

export function deleteSingleNFTFailure(error) {
  return {
    type: DELETE_SINGLE_NFT_FAILURE,
    error,
  };
}

export function uploadNFTFilesStart(data) {
  return {
    type: UPLOAD_NFT_FILES_START,
    data,
  };
}

export function uploadNFTFilesSuccess(data) {
  return {
    type: UPLOAD_NFT_FILES_SUCCESS,
    data,
  };
}

export function uploadNFTFilesFailure(error) {
  return {
    type: UPLOAD_NFT_FILES_FAILURE,
    error,
  };
}

export function searchNFTImageStart(data) {
  return {
    type: SEARCH_NFT_IMAGE_START,
    data,
  };
}

export function searchNFTImageSuccess(data) {
  return {
    type: SEARCH_NFT_IMAGE_SUCCESS,
    data,
  };
}

export function searchNFTImageFailure(error) {
  return {
    type: SEARCH_NFT_IMAGE_FAILURE,
    error,
  };
}

export function paginateListOfNFTStart(data) {
  return {
    type: PAGINATE_LIST_OF_NFT_START,
    data,
  };
}

export function sortListOfNFTStart(data) {
  return {
    type: SORT_LIST_OF_NFT_START,
    data,
  };
}

export function sortListOfNFTSuccess(data) {
  return {
    type: SORT_LIST_OF_NFT_SUCCESS,
    data,
  };
}

export function sortListOfNFTFailure(error) {
  return {
    type: SORT_LIST_OF_NFT_FAILURE,
    error,
  };
}

export function editNFTDetails(name, value) {
  return {
    type: EDIT_NFT_DETAILS,
    name,
    value
  };
}

export function updateNFTDetailsStart(data) {
  return {
    type: UPDATE_NFT_DETAILS_START,
    data,
  };
}

export function updateNFTDetailsSuccess(data) {
  return {
    type: UPDATE_NFT_DETAILS_SUCCESS,
    data,
  };
}

export function updateNFTDetailsFailure(error) {
  return {
    type: UPDATE_NFT_DETAILS_FAILURE,
    error,
  };
}

export function addNFTImageStart(data) {
  return {
    type: ADD_NFT_IMAGE_START,
    data,
  };
}

export function addNFTImageSuccess(data) {
  return {
    type: ADD_NFT_IMAGE_SUCCESS,
    data,
  };
}

export function addNFTImageFailure(error) {
  return {
    type: ADD_NFT_IMAGE_FAILURE,
    error,
  };
}

export function fetchNftImagesListAfterDelete(data) {
  return {
    type: FETCH_NFT_IMAGES_LIST_AFTER_DELETE,
    data,
  };
}

export function userMintNftImagesStart(data) {
  return {
    type: USER_MINT_NFT_IMAGES_START,
    data,
  };
}

export function userMintNftImagesSuccess(data) {
  return {
    type: USER_MINT_NFT_IMAGES_SUCCESS,
    data,
  };
}

export function userMintImagesFailure(error) {
  return {
    type: USER_MINT_NFT_IMAGES_FAILURE,
    error,
  };
}

export function userMintImagesClearStart(error) {
  return {
    type: USER_MINT_NFT_IMAGES_CLEAR_START,
    error,
  };
}

export function userUpdateNftImageMintStart(data) {
  return {
    type: USER_UPDATE_NFT_IMAGE_MINT_START,
    data,
  };
}

export function userUpdateNftImageMintSuccess(data) {
  return {
    type: USER_UPDATE_NFT_IMAGE_MINT_SUCCESS,
    data,
  }
}

export function userUpdateNftImageMintFailure(error) {
  return {
    type: USER_UPDATE_NFT_IMAGE_MINT_FAILURE,
    error,
  }
}
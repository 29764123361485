import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  IconButton,
  Box,
  Button,
  Typography,
  Divider,
  Chip,
  Stack,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { getSuccessNotificationMessage } from "src/components/ToastNotification";
import useAuth from "src/hooks/useAuth";
import configuration from "react-global-configuration";

const BorderdBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AccountModal = ({ open, handleClose }) => {
  const { auth, hanldeLogout } = useAuth();

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    getSuccessNotificationMessage("Wallet address copied");
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography variant={"h4"}>Account Details</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <BorderdBox>
            <Box display="flex" gap="1rem" alignItems={"center"} mb={2}>
              <Typography variant="h6">
                {auth.wallet.accounts?.substr(0, 8)}...
                {auth.wallet.accounts?.substr(auth.wallet.accounts?.length - 8)}
              </Typography>
              <IconButton onClick={() => copyToClipboard(auth.wallet.accounts)}>
                <ContentCopyOutlinedIcon
                  sx={{ width: "1rem", height: "1rem" }}
                />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" gap="1rem">
                  <Typography variant="subtitle1">BUSD Balance : </Typography>

                  <Chip
                    label={`${Number(auth.wallet.ethBalance).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 2 }
                    )} ${
                      configuration.get("configData.native_currency_symbol")
                        ? configuration.get("configData.native_currency_symbol")
                        : "BNB"
                    }`}
                    size="small"
                    variant="contained"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" gap="1rem">
                  <Typography variant="subtitle1">
                    {auth.wallet.balanceTokens[0].name} Balance :
                  </Typography>

                  <Chip
                    label={`${Number(
                      auth.wallet.BUSDTokenBalance
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })} 
                    ${auth.wallet.balanceTokens[0].name}`}
                    size="small"
                    variant="contained"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" gap="1rem">
                  <Typography variant="subtitle1">
                    {auth.wallet.balanceTokens[1].name} Balance :{" "}
                  </Typography>

                  <Chip
                    label={`${Number(
                      auth.wallet.BUSDXTokenBalance
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })} 
                    ${auth.wallet.balanceTokens[1].name}`}
                    size="small"
                    variant="contained"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button variant="contained" onClick={hanldeLogout}>
                Logout
              </Button>
            </Box>
          </BorderdBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AccountModal;

AccountModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

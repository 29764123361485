import { call, select, put, takeLatest, all } from "redux-saga/effects";

import {
  fetchCreatorContractListSuccess,
  fetchCreatorContractListFailure,
  fetchCreatorSingleContractSuccess,
  fetchCreatorSingleContractFailure,
  creatorContractSaveSuccess,
  creatorContractSaveFailure,
  creatorContractDeleteSuccess,
  creatorContractDeleteFailure,
  updateContractDeployAccessSuccess,
  updateContractDeployAccessFailure,
  deployContractSaveSuccess,
  deployContractSaveFailure,
  fetchWhitelistedWalletListSuccess,
  fetchWhitelistedWalletListFailure,
  saveWhitelistedWalletSuccess,
  saveWhitelistedWalletFailure,
  setMintFeeUpdateSuccess,
  setMintFeeUpdateFailure,
} from "../actions/CreatorContractAction";

import {
  fetchNftListStart,
} from "../actions/NftAction";

import api from "src/Environment";
import {
  FETCH_CREATOR_CONTRACT_LIST_START,
  FETCH_CREATOR_SINGLE_CONTRACT_START,
  CREATOR_CONTRACT_SAVE_START,
  CREATOR_CONTRACT_DELETE_START,
  UPDATE_CONTRACT_DEPLOY_ACCESS_START,
  DEPLOY_CONTRACT_SAVE_START,
  FETCH_WHITELISTED_WALLET_LIST_START,
  SAVE_WHITELISTED_WALLET_START,
  SET_MINT_FEE_UPDATE_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";

function* creatorContractListAPI() {
  try {
    const response = yield api.postMethod("creator/contracts_list");
    if (response.data.success) {
      yield put(fetchCreatorContractListSuccess(response.data.data));
    } else {
      yield put(fetchCreatorContractListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCreatorContractListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorSingleContractAPI(action) {
  try {

    const response = yield api.postMethod("creator/contracts_view", action.data);

    if (response.data.success) {
      yield put(fetchCreatorSingleContractSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCreatorSingleContractFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorContractSaveAPI(action) {
  try {

    const response = yield api.postMethod("creator/contracts_save", action.data);

    yield put(creatorContractSaveSuccess(response.data.data));
    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(creatorContractSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorContractDeleteAPI(action) {
  try {

    const response = yield api.postMethod("creator/contracts_delete", action.data);

    yield put(creatorContractDeleteSuccess(response.data.data));
    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(creatorContractDeleteFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateContractDeployAccessAPI(action) {
  try {

    const response = yield api.postMethod("creator/update_deploy_access", action.data);

    yield put(updateContractDeployAccessSuccess(response.data));
    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateContractDeployAccessFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deployContractSaveAPI(action) {
  try {

    const response = yield api.postMethod("creator/deploy_contract_save", action.data);

    yield put(deployContractSaveSuccess(response.data.data));
    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deployContractSaveFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWhitelistedWalletListAPI() {
  try {
    const response = yield api.postMethod("creator/whitelisted_wallet_list");
    if (response.data.success) {
      yield put(fetchWhitelistedWalletListSuccess(response.data.data));
    } else {
      yield put(fetchWhitelistedWalletListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWhitelistedWalletListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* saveWhitelistedWalletAPI(action) {
  try {

    const response = yield api.postMethod("creator/whitelisted_wallet_save", action.data);

    if (response.data.success) {
      yield put(deployContractSaveSuccess(response.data.data));
      yield call(saveWhitelistedWalletSuccess, response.data.message);
      yield call(getSuccessNotificationMessage, response.data.message);
      yield put(fetchNftListStart())
    } else {
      yield put(saveWhitelistedWalletFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveWhitelistedWalletFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* setMintFeeUpdateAPI(action) {
  try {

    const response = yield api.postMethod("creator/set_mint_fee_update", action.data);

    yield put(setMintFeeUpdateSuccess(response.data.data));
    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(setMintFeeUpdateFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}
export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_CREATOR_CONTRACT_LIST_START, creatorContractListAPI),
    yield takeLatest(FETCH_CREATOR_SINGLE_CONTRACT_START, creatorSingleContractAPI),
    yield takeLatest(CREATOR_CONTRACT_SAVE_START, creatorContractSaveAPI),
    yield takeLatest(CREATOR_CONTRACT_DELETE_START, creatorContractDeleteAPI),
    yield takeLatest(UPDATE_CONTRACT_DEPLOY_ACCESS_START, updateContractDeployAccessAPI),
    //yield takeLatest(DEPLOY_CONTRACT_SAVE_START, deployContractSaveAPI),
    yield takeLatest(FETCH_WHITELISTED_WALLET_LIST_START, fetchWhitelistedWalletListAPI),
    yield takeLatest(SAVE_WHITELISTED_WALLET_START, saveWhitelistedWalletAPI),
    yield takeLatest(SET_MINT_FEE_UPDATE_START, setMintFeeUpdateAPI),
  ]);
}

import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import PageSaga from "./PageSaga";
import WalletSaga from "./WalletSaga";
import VerificationDocumentSaga from "./VerificationDocumentSaga";
import SubscriptionSaga from "./SubscriptionSaga";
import KycDocumentSaga from "./KycDocumentSaga";
import ProjectSaga from "./ProjectSaga";
import FaqSaga from './FaqSaga'
import StakeUnstakeSaga from "./StakeUnstakeSaga"
import TokenSaga from './TokenSaga';
import NFTImagesSaga from './NFTImagesSaga';
import CreatorSaga from "./CreatorSaga"
import CreatorContractSaga from "./CreatorContractSaga"
import NftSaga from './NftSaga';
import UserContractSaga from './UserContractSaga';

export default function* rootSaga() {
  yield all([fork(UserSaga)]);
  yield all([fork(PageSaga)]);
  yield all([fork(VerificationDocumentSaga)]);
  yield all([fork(WalletSaga)]);
  yield all([fork(SubscriptionSaga)]);
  yield all([fork(KycDocumentSaga)]);
  yield all([fork(ProjectSaga)]);
  yield all([fork(FaqSaga)]);
  yield all([fork(StakeUnstakeSaga)]);
  yield all([fork(TokenSaga)]);
  yield all([fork(NFTImagesSaga)]);
  yield all([fork(CreatorSaga)]);
  yield all([fork(CreatorContractSaga)]);
  yield all([fork(NftSaga)]);
  yield all([fork(UserContractSaga)]);
}

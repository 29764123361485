import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme, alpha } from "@mui/material/styles";
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  Typography,
} from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
// utils
import cssStyles from "../../utils/cssStyles";
// config
import { HEADER } from "../../config";
// components
import Logo from "../../components/Logo";
import Label from "../../components/Label";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { Link } from "react-router-dom";
import ProfilePopver from "./ProfilePopover";
import { useCallback, useEffect, useState } from "react";
import ConnectWalletModal from "src/modals/ConnectWalletModal";
import useAuth from "src/hooks/useAuth";
import AccountModal from "src/modals/AccountModal";
import useCurrentRole from "src/hooks/useCurrentRole";

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

const AccountModalButton = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  padding: "0.2rem 0.4rem",
}));

const AccountBalanceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const AccountWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: alpha(theme.palette.grey[500], 0.25),
  padding: "0.25rem 0.5rem",
  borderRadius: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "md");

  const isHome = pathname === "/";

  const { auth } = useAuth();

  const userRole = useCurrentRole();

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [accountModalStatus, setAccountModalStatus] = useState(false);

  const hanldeConnectWalletModalOpen = () => {
    setConnectWalletModalStatus(true);
  };

  const hanldeConnectWalletModalClose = useCallback(() => {
    setConnectWalletModalStatus(false);
  }, []);

  const hanldeAccountModalOpen = () => {
    setAccountModalStatus(true);
  };

  const hanldeAccountModalClose = useCallback(() => {
    setAccountModalStatus(false);
  }, []);

  useEffect(() => {
    if (auth.wallet.authStatus) {
      hanldeConnectWalletModalClose();
    } else {
      hanldeAccountModalClose();
    }
  }, [auth.wallet.authStatus]);

  return (
    <>
      <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              ...cssStyles(theme).bgBlur(),
              height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            }),
          }}
        >
          <Container
            maxWidth="true"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Logo />
            <Box sx={{ flexGrow: 1 }} />

            {isDesktop && (
              <MenuDesktop
                isOffset={isOffset}
                isHome={isHome}
                navConfig={navConfig}
              />
            )}

            <Box display={"flex"} gap="1rem">
              {auth.wallet.authStatus ? (
                <>
                  <AccountWrapper>
                    <AccountBalanceText variant="subtitle1">
                      {Number(auth.wallet.BUSDXTokenBalance).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}{" "}
                      {auth.wallet.balanceTokens[1].name}
                    </AccountBalanceText>
                    <AccountModalButton
                      variant="contained"
                      onClick={hanldeAccountModalOpen}
                    >
                      {auth.wallet.accounts?.substr(0, 4)}...
                      {auth.wallet.accounts?.substr(
                        auth.wallet.accounts?.length - 4
                      )}
                    </AccountModalButton>
                  </AccountWrapper>
                </>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => hanldeConnectWalletModalOpen()}
                >
                  Connect Wallet
                </Button>
              )}

              {isDesktop ? (
                auth.user.authStatus ? (
                  <>
                    {userRole === "user" ? (
                      <Button
                        variant="contained"
                        component={Link}
                        to="/auth/login"
                      >
                        Creator Login
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        component={Link}
                        to="/creator/dashboard"
                      >
                        Dashboard
                      </Button>
                    )}
                  </>
                ) : (
                  <Button variant="contained" component={Link} to="/auth/login">
                    Creator Login
                  </Button>
                )
              ) : null}
              {auth.user.authStatus && userRole === "user" && (
                <ProfilePopver />
              )}
            </Box>

            {!isDesktop && (
              <MenuMobile
                isOffset={isOffset}
                isHome={isHome}
                navConfig={navConfig}
              />
            )}
          </Container>
        </ToolbarStyle>

        {isOffset && <ToolbarShadowStyle />}
      </AppBar>
      <ConnectWalletModal
        open={connectWalletModalStatus}
        handleClose={hanldeConnectWalletModalClose}
      />
      <AccountModal
        open={accountModalStatus}
        handleClose={hanldeAccountModalClose}
      />
    </>
  );
}

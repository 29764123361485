import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  IconButton,
  Box,
  Button,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Image from "src/components/Image";
import useResponsive from "src/hooks/useResponsive";
import useAuth from "src/hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

const LoginConnectorsWrapper = styled(Box)(({ theme }) => ({
  minWidth: useResponsive("up", "md") ? "400px" : "300px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius,
}));

const LoginConnector = styled(Box)(({ theme }) => ({}));

const LoginConnectorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transpatent",
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,

  "&:hover": {
    backgroundColor: theme.palette.background.neutral,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConnectWalletModal = ({ open, handleClose }) => {
  const { loginConnectors, handleConnector, activatingConnector } = useAuth();

  const isMobile = useResponsive("down", "md");

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography variant={"h4"}>Connect Wallet</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <LoginConnectorsWrapper my={2}>
            {loginConnectors && loginConnectors.length > 0 && (
              <>
                {loginConnectors.map((connector, index) => (
                  <LoginConnector key={`connectos-${index}`}>
                    <LoginConnectorButton
                      variant={"text"}
                      disabled={!connector.isAvailable || activatingConnector != undefined}
                      onClick={() =>
                        handleConnector(connector.connectorFunction)
                      }
                    >
                      <Box display="flex" gap={2} alignItems={"center"}>
                        <Image
                          src={connector.logo}
                          disabledEffect
                          alt={connector.name}
                          sx={{ maxWidth: "2rem" }}
                        />
                        <Typography variant="subtitle1">
                          {connector.name}
                        </Typography>
                      </Box>
                      <Box display="flex" gap={1} alignItems="center">
                        {!connector.isAvailable && (
                          <Chip
                            label={"Install"}
                            color="error"
                            size="small"
                            component="a"
                            href={connector.installUrl}
                            target="_blank"
                            variant="outlined"
                            clickable
                          />
                        )}
                        {connector.is_popular && (
                          <Chip
                            label={"Popular"}
                            color="success"
                            size="small"
                          />
                        )}
                        {activatingConnector ===
                          connector.connectorFunction && (
                          <CircularProgress size={"1.25rem"} />
                        )}
                      </Box>
                    </LoginConnectorButton>
                    {index < loginConnectors.length - 1 && <Divider />}
                  </LoginConnector>
                ))}
              </>
            )}
          </LoginConnectorsWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectWalletModal;

ConnectWalletModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

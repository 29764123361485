import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Grid,
  Link,
  Divider,
  Container,
  Typography,
  Stack,
  Box,
} from "@mui/material";
// routes
import { PATH_PAGE } from "../../routes/paths";
// components
import Logo from "../../components/Logo";
import SocialsButton from "../../components/SocialsButton";
import configuration from "react-global-configuration";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const isAtleastOneLinkExist = (links) => {
    const linksFound = links.filter((link) => link.link).length > 0;

    const socialLinks = Object.assign(
      {},
      ...links.map((links) => ({ [links.name]: links.link }))
    );

    if (linksFound) {
      return (
        <>
          <Box>
            <Typography
              component="p"
              variant="subtitle1"
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              Social Links
            </Typography>
            <Stack
              direction="row"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={{ mt: 1, mb: 1 }}
            >
              <SocialsButton sx={{ mx: 0.5 }} links={socialLinks} />
            </Stack>
          </Box>
        </>
      );
    }
  };

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Stack
          spacing={3}
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 1, md: 3 }}
          justifyContent={{ xs: "center", md: "space-around" }}
        >
          <Logo sx={{ mx: { xs: "auto", md: "inherit" } }} />
          {configuration.get("configData.footer_pages1") &&
            configuration.get("configData.footer_pages1").length > 0 && (
              <Stack spacing={2}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  sx={{ textAlign: { xs: "center", md: "start" } }}
                >
                  Nft Launchpad
                </Typography>
                {configuration
                  .get("configData.footer_pages1")
                  .map((link, index) => (
                    <>
                      <Link
                        to={`/page/${link.static_page_unique_id}`}
                        key={link.title}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{
                          display: "block",
                          textAlign: { xs: "center", md: "start" },
                          textTransform: "capitalize",
                        }}
                      >
                        {link.title}
                      </Link>
                    </>
                  ))}
              </Stack>
            )}
          {configuration.get("configData.footer_pages2") &&
            configuration.get("configData.footer_pages2").length > 0 && (
              <Stack spacing={2}>
                <Typography
                  component="p"
                  variant="subtitle1"
                  sx={{ textAlign: { xs: "center", md: "start" } }}
                >
                  Discover
                </Typography>
                {configuration
                  .get("configData.footer_pages2")
                  .map((link, index) => (
                    <>
                      <Link
                        to={link.title}
                        key={link.title}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{
                          display: "block",
                          textAlign: { xs: "center", md: "start" },
                          textTransform: "capitalize",
                        }}
                      >
                        {link.title}
                      </Link>
                    </>
                  ))}
              </Stack>
            )}
          {isAtleastOneLinkExist([
            {
              name: "instagram",
              link: configuration.get("configData.instagram_link"),
            },
            {
              name: "facebook",
              link: configuration.get("configData.facebook_link"),
            },
            {
              name: "linkedin",
              link: configuration.get("configData.linkedin_link"),
            },
            {
              name: "twitter",
              link: configuration.get("configData.twitter_link"),
            },
          ])}
        </Stack>
        {configuration.get("configData.copyright_content") && (
          <Typography
            component="p"
            variant="body2"
            sx={{
              mt: { xs: 5, md: 10 },
              pb: 5,
              fontSize: 13,
              textAlign: "center",
            }}
          >
            {configuration.get("configData.copyright_content")}
          </Typography>
        )}
      </Container>
    </RootStyle>
  );
}

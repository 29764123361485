import {
    FETCH_USER_CONTRACT_LIST_START,
    FETCH_USER_CONTRACT_LIST_SUCCESS,
    FETCH_USER_CONTRACT_LIST_FAILURE,
    FETCH_USER_SINGLE_CONTRACT_START,
    FETCH_USER_SINGLE_CONTRACT_SUCCESS,
    FETCH_USER_SINGLE_CONTRACT_FAILURE,
} from './ActionConstant';

export function fetchUserContractListStart(data) {
    return {
        type: FETCH_USER_CONTRACT_LIST_START,
        data,
    }
}

export function fetchUserContractListSuccess(data) {
    return {
        type: FETCH_USER_CONTRACT_LIST_SUCCESS,
        data,
    }
}

export function fetchUserContractListFailure(error) {
    return {
        type: FETCH_USER_CONTRACT_LIST_FAILURE,
        error,
    }
}

export function fetchUserSingleContractStart(data) {
    return {
        type: FETCH_USER_SINGLE_CONTRACT_START,
        data,
    }
}

export function fetchUserSingleContractSuccess(data) {
    return {
        type: FETCH_USER_SINGLE_CONTRACT_SUCCESS,
        data,
    }
}

export function fetchUserSingleContractFailure(data) {
    return {
        type: FETCH_USER_SINGLE_CONTRACT_FAILURE,
        data,
    }
}
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem , IconButton } from "@mui/material";
// hooks
import useAuth from "src/hooks/useAuth";
import useIsMountedRef from "src/hooks/useIsMountedRef";
// components
import MyAvatar from "src/components/MyAvatar";
import MenuPopover from "src/components/MenuPopover";
import { IconButtonAnimate } from "src/components/animate";
import { getSuccessNotificationMessage } from "src/components/ToastNotification";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    linkTo: "/account/profile",
  },
  {
    label: "Wallet",
    linkTo: "/account/wallet",
  },
  {
    label: "Minted Contracts",
    linkTo: "/account/minted_contracts",
  },
];

// ----------------------------------------------------------------------

const ProfilePopver = (props) => {
  const navigate = useNavigate();

  const { auth, hanldeLogout } = useAuth();

  const {user} = props;

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    getSuccessNotificationMessage("Wallet address copied");
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.data?.name}
          </Typography>
          {auth.wallet.authStatus && (
            <>
              <Box display={"flex"} gap="0.5rem" alignItems="center">
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary" }}
                  noWrap
                >
                  {`${auth.wallet.accounts?.substring(
                    0,
                    6
                  )}...${auth.wallet.accounts?.substring(
                    auth.wallet.accounts?.length - 6
                  )}`}
                </Typography>
                <IconButton onClick={copyToClipboard}>
                  <ContentCopyOutlinedIcon
                    sx={{ width: "1rem", height: "1rem" }}
                  />
                </IconButton>
              </Box>
            </>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={hanldeLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

const mapStateToProps = (state) => ({
  user : state.users.profile
})

export default connect(mapStateToProps , null)(ProfilePopver)
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Typography, IconButton } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// components
import MyAvatar from "../../../components/MyAvatar";
import { getSuccessNotificationMessage } from "src/components/ToastNotification";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { connect } from "react-redux";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

const NavbarAccount = ({ isCollapse, user }) => {
  const { auth } = useAuth();

  const copyToClipboard = (walletAddress) => {
    navigator.clipboard.writeText(walletAddress);
    getSuccessNotificationMessage("Wallet address copied");
  };

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={"/creator/profile"}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user.data?.name}
          </Typography>
          {auth.wallet.authStatus && (
            <Box display="flex" alignItems="center" gap={"0.5rem"}>
              <Typography
                variant="body2"
                noWrap
                sx={{ color: "text.secondary" }}
              >
                {auth.wallet.accounts.substr(0, 4)}...
                {auth.wallet.accounts.substr(auth.wallet.accounts.length - 4)}
              </Typography>
              <IconButton onClick={copyToClipboard}>
                <ContentCopyOutlinedIcon
                  sx={{ width: "1rem", height: "1rem" }}
                />
              </IconButton>
            </Box>
          )}
        </Box>
      </RootStyle>
    </Link>
  );
};

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};


const mapStateToProps = (state) => ({
  user: state.users.profile,
});

export default connect(mapStateToProps, null)(NavbarAccount);

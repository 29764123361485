import { call, put, takeLatest, all } from "redux-saga/effects";
import api from "src/Environment";

import {
  getErrorNotificationMessage,
} from "src/components/ToastNotification";

import {
  STAKE_TOKEN_START,
  STAKE_ROUND_CHECK_START,
} from "../actions/ActionConstant";

import {
  stakeTokenSuccess,
  stakeTokenFailure,
  stakeRoundCheckSuccess,
  stakeRoundCheckFailure,
} from "../actions/StakeUnstakeAction";

function* stakeTokenAPI(action) {
  try {
    const response = yield api.postMethod(
      "user_staked_amount_save",
      action.data
    );
    if (response.data.success) {
      yield put(stakeTokenSuccess(response.data.data));
    } else {
      yield put(stakeTokenFailure(response.data.error));
    }
  } catch (error) {
    yield put(stakeTokenFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* stakeRoundCheckAPI(action) {
  try {
    const response = yield api.postMethod(
      "subscription_round_check",
      action.data
    );
    if (response.data.success) {
      yield put(stakeRoundCheckSuccess(response.data.data));
    } else {
      yield put(stakeRoundCheckFailure(response.data.error));
    }
  } catch (error) {
    yield put(stakeRoundCheckFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(STAKE_TOKEN_START, stakeTokenAPI)]);
  yield all([yield takeLatest(STAKE_ROUND_CHECK_START, stakeRoundCheckAPI)]);
}

import {
  CREATOR_LOGIN_START,
  FETCH_CREATOR_PROFILE_START,
  UPDATE_CREATOR_DETAILS_START,
  UPDATE_CREATOR_DETAILS_SUCCESS,
  UPDATE_CREATOR_DETAILS_FAILURE,
  CREATOR_CHANGE_PASSWORD_START,
  CREATOR_CHANGE_PASSWORD_SUCCESS,
  CREATOR_CHANGE_PASSWORD_FAILURE,
  CREATOR_LOGOUT_START,
  CREATOR_LOGOUT_SUCCESS,
  CREATOR_LOGOUT_FAILURE,
  CREATOR_DELETE_ACCOUNT_START,
  CREATOR_DELETE_ACCOUNT_SUCCESS,
  CREATOR_DELETE_ACCOUNT_FAILURE,
  FETCH_CREATOR_DASHBOARD_START,
  FETCH_CREATOR_DASHBOARD_SUCCESS,
  FETCH_CREATOR_DASHBOARD_FAILURE,
  CREATOR_REGISTER_START,
  CREATOR_REGISTER_SUCCESS,
  CREATOR_REGISTER_FAILURE
} from "./ActionConstant";

export function creatorLoginStart(data) {
  return {
    type: CREATOR_LOGIN_START,
    data,
  };
}

export function fetchCreatorProfileStart(data) {
  return {
    type: FETCH_CREATOR_PROFILE_START,
    data,
  };
}

export function updateCreatorDetailsStart(data) {
  return {
    type: UPDATE_CREATOR_DETAILS_START,
    data,
  };
}

export function updateCreatorDetailsSuccess(data) {
  return {
    type: UPDATE_CREATOR_DETAILS_SUCCESS,
    data,
  };
}

export function updateCreatorDetailsFailure(error) {
  return {
    type: UPDATE_CREATOR_DETAILS_FAILURE,
    error,
  };
}

export function creatorChangePasswordStart(data) {
  return {
    type: CREATOR_CHANGE_PASSWORD_START,
    data,
  };
}

export function creatorChangePasswordSuccess(data) {
  return {
    type: CREATOR_CHANGE_PASSWORD_SUCCESS,
    data,
  };
}

export function creatorChangePasswordFailure(error) {
  return {
    type: CREATOR_CHANGE_PASSWORD_FAILURE,
    error,
  };
}

export function logoutStart(data) {
  return {
    type: CREATOR_LOGOUT_START,
    data,
  };
}

export function logoutSuccess(data) {
  return {
    type: CREATOR_LOGOUT_SUCCESS,
    data,
  };
}

export function logoutFailure(error) {
  return {
    type: CREATOR_LOGOUT_FAILURE,
    error,
  };
}

export function creatorDeleteAccountStart(data) {
  return {
    type: CREATOR_DELETE_ACCOUNT_START,
    data,
  };
}

export function creatorDeleteAccountSuccess(data) {
  return {
    type: CREATOR_DELETE_ACCOUNT_SUCCESS,
    data,
  };
}

export function creatorDeleteAccountFailure(error) {
  return {
    type: CREATOR_DELETE_ACCOUNT_FAILURE,
    error,
  };
}

export function fetchDashboardStart(data) {
  return {
    type: FETCH_CREATOR_DASHBOARD_START,
    data,
  };
}

export function fetchDashboardSuccess(data) {
  return {
    type: FETCH_CREATOR_DASHBOARD_SUCCESS,
    data,
  };
}

export function fetchDashboardFailure(error) {
  return {
    type: FETCH_CREATOR_DASHBOARD_FAILURE,
    error,
  };
}

export function creatorRegisterStart(data){
  return {
    type : CREATOR_REGISTER_START,
    data
  }
}

export function creatorRegisterSuccess(data) {
  return {
    type: CREATOR_REGISTER_SUCCESS,
    data,
  };
}

export function creatorRegisterFailure(error) {
  return {
    type: CREATOR_REGISTER_FAILURE,
    error,
  };
}
import {
  UPDATE_CREATOR_DETAILS_START,
  UPDATE_CREATOR_DETAILS_SUCCESS,
  UPDATE_CREATOR_DETAILS_FAILURE,
  CREATOR_CHANGE_PASSWORD_START,
  CREATOR_CHANGE_PASSWORD_SUCCESS,
  CREATOR_CHANGE_PASSWORD_FAILURE,
  CREATOR_LOGOUT_START,
  CREATOR_LOGOUT_SUCCESS,
  CREATOR_LOGOUT_FAILURE,
  CREATOR_DELETE_ACCOUNT_START,
  CREATOR_DELETE_ACCOUNT_SUCCESS,
  CREATOR_DELETE_ACCOUNT_FAILURE,
  FETCH_CREATOR_DASHBOARD_START,
  FETCH_CREATOR_DASHBOARD_SUCCESS,
  FETCH_CREATOR_DASHBOARD_FAILURE,
  CREATOR_REGISTER_START,
  CREATOR_REGISTER_SUCCESS,
  CREATOR_REGISTER_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  profileInputData: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    status: null,
  },
  changePassword: {
    data: {},
    loading: false,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  logout: {
    data: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteAccount: {
    data: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  dashboard: {
    data: {},
    loading: false,
    error: false,
  },
  register: {
    data: {},
    loading: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
};

const creatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CREATOR_DETAILS_START:
      return {
        ...state,
        profileInputData: {
          ...state.profileInputData,
          data: action.data,
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading...please wait",
        },
      };

    case UPDATE_CREATOR_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
        profileInputData: {
          ...state.profileInputData,
          data: {},
          loading: false,
          status: true,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_CREATOR_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: state.profile.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
        profileInputData: {
          ...state.profileInputData,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
          status: true,
        },
      };
    case CREATOR_CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };
    case CREATOR_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: {},
          success: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_LOGOUT_START:
      return {
        ...state,
        logout: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };
    case CREATOR_LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          data: {},
          success: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_LOGOUT_FAILURE:
      return {
        ...state,
        logout: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };
    case CREATOR_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: {
          data: {},
          success: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_CREATOR_DASHBOARD_START:
      return {
        ...state,
        dashboard: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CREATOR_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CREATOR_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboard: {
          data: {},
          loading: false,
          error: false,
        },
      };
    case CREATOR_REGISTER_START:
      return {
        ...state,
        register: {
          data: action.data,
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };
    case CREATOR_REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          data:  action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CREATOR_REGISTER_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    default:
      return state;
  }
};

export default creatorReducer;

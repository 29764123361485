import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, Select, FormControl, InputLabel, Box } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelect({ name, label, children, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControl sx={{ minWidth: "200px" }}>
      <Controller
        id={name}
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <InputLabel error={!!error}>{label}</InputLabel>{" "}
            <Select
              {...field}
              name={name}
              label={label}
              error={!!error}
              helperText={error?.message}
              fullWidth
              {...other}
            >
              {children}
            </Select>
            {error && (
              <FormHelperText error={!!error}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
}

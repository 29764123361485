import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import configuration from "react-global-configuration";
import { useDispatch, useSelector } from "react-redux";
// utils
import { isValidToken, decodedToken } from "../../utils/jwt";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { UnsupportedChainIdError } from "@web3-react/core";
import { formatEther } from "@ethersproject/units";
import { MetaMask, walletconnect, walletConnectProvider } from "./connectors";
import { useEagerConnect, useInactiveListener } from "./web3Hooks";
import { InjectedConnector } from "@web3-react/injected-connector";
import MetaMaskLogo from "src/assets/images/wallet-img/metamask.png";
import WalletConnectLogo from "src/assets/images/wallet-img/wallet-connect.png";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "src/components/ToastNotification";
import Token from "src/abis/Token.json";
import {
  userLoginStart,
  fetchUserDetailsStart,
  userLogoutStart,
} from "src/store/actions/UserAction";
import {
  creatorLoginStart,
  fetchCreatorProfileStart,
  logoutStart,
} from "src/store/actions/CreatorAction";
import useCookies from "src/hooks/useCookies";
import { useNavigate } from "react-router";
// ----------------------------------------------------------------------

export const AuthContext = createContext({});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({
    wallet: {
      loading: true,
      accounts: null,
      connectWalletStatus: false,
      ethBalance: null,
      BUSDTokenData: null,
      BUSDTokenBalance: null,
      BUSDXTokenData: null,
      BUSDXTokenBalance: null,
      logoutStatus: localStorage.getItem("inital_connect"),
      authStatus: null,
      balanceTokens: [
        {
          name: "BUSD",
        },
        { name: "BUSDX" },
      ],
    },
    user: {
      userId: null,
      userUniqueId: null,
      authStatus: null,
    },
  });

  const loginData = useSelector((state) => state.users.loginInputData);
  const profileState = useSelector((state) => state.users.profile);

  //web3 login
  const context = useWeb3React();
  const dispatch = useDispatch();
  const { ethereum } = window;
  const navigate = useNavigate();
  const { getCookie } = useCookies();
  let accessToken = getCookie("accessToken");
  let tokenData = decodedToken(accessToken);

  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const chainDetails = useMemo(
    () => ({
      netID: configuration.get("configData.network_id")
        ? Number(configuration.get("configData.network_id"))
        : 56,
      chainIdHex: configuration.get("configData.chain_id_hexacode")
        ? configuration.get("configData.chain_id_hexacode")
        : "0x38",
      rpcUrl: configuration.get("configData.rpc_url")
        ? configuration.get("configData.rpc_url")
        : "https://data-seed-prebsc-1-s1.binance.org:8545/",
      chainName: configuration.get("configData.chain_name")
        ? configuration.get("configData.chain_name")
        : "Binance - Testnet",
      nativeCurrencyName: configuration.get("configData.native_currency_name")
        ? configuration.get("configData.native_currency_name")
        : "Binace",
      nativeCurrencySymbol: configuration.get(
        "configData.native_currency_symbol"
      )
        ? configuration.get("configData.native_currency_symbol")
        : "BNB",
      nativeCurrencyDecimals: configuration.get(
        "configData.native_currency_decimals"
      )
        ? Number(configuration.get("configData.native_currency_decimals"))
        : 18,
      blockExplorerUrl: configuration.get("configData.block_explorer_urls")
        ? configuration.get("configData.block_explorer_urls")
        : "https://testnet.bscscan.com",
    }),
    []
  );

  const contractAddresses = useMemo(
    () => ({
      tokenContractAddress: configuration.get("configData.lp_contract_address"),
      projectContractAddress: configuration.get(
        "configData.project_contract_address"
      ),
    }),
    []
  );

  const supportedChains = useMemo(
    () => [
      {
        chainId: [chainDetails.netID],
        name: chainDetails.nativeCurrencyName,
        symbol: chainDetails.nativeCurrencySymbol,
        isTestNet: false,
      },
    ],
    []
  );

  const [activatingConnector, setActivatingConnector] = useState();

  const loginConnectors = useMemo(
    () => [
      {
        name: "MetaMask",
        logo: MetaMaskLogo,
        is_popular: true,
        isAvailable: window.ethereum != undefined,
        connectorFunction: MetaMask,
        installUrl: "https://metamask.io/",
      },
      {
        name: "WalletConnect",
        logo: WalletConnectLogo,
        is_popular: false,
        isAvailable: true,
        installUrl: "https://walletconnect.com/",
        connectorFunction: walletconnect,
      },
    ],
    []
  );
  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect(localStorage.getItem("inital_connect"));

  const handleConnector = useCallback(
    async (connector) => {
      const network =
        ethereum && ethereum.networkVersion ? ethereum.networkVersion : "";

      setAuth({
        ...auth,
        wallet: {
          ...auth.wallet,
          loading: true,
          connectWalletStatus: true,
        },
      });

      setActivatingConnector(connector);
      if (connector instanceof WalletConnectConnector) {
        await walletConnectProvider
          .enable()
          .then(() => {
            setActivatingConnector(undefined);
          })
          .catch(() => {
            setActivatingConnector(undefined);
          });
      }

      if (connector instanceof InjectedConnector) {
        connector.walletConnectProvider = undefined;
        if (chainDetails.netID === Number(network)) {
          console.log("same network");
          activate(connector);
        } else {
          console.log("change network");
          changeNetwork();
        }
      } else {
        activate(connector);
      }
    },
    [activatingConnector, auth]
  );

  const changeNetwork = async () => {
    // MetaMask injects the global API into window.ethereum

    if (ethereum) {
      try {
        // check if the chain to connect to is installed
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: chainDetails.chainIdHex }], // chainId must be in hexadecimal numbers
          })
          .then(() => {
            activate(MetaMask);
          })
          .catch((e) => {
            if (e.code === 4902) {
              addNetwork();
            } else {
              setActivatingConnector(undefined);
              getErrorNotificationMessage(e.message);
            }
          });
        //await ethereum.enable();
      } catch (error) {
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          addNetwork();
        }
      }
    } else {
      // if no window.ethereum then MetaMask is not installed
    }
  };

  const addNetwork = async () => {
    try {
      await window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chainDetails.chainIdHex,
              rpcUrls: [chainDetails.rpcUrl],
              chainName: chainDetails.chainName,
              nativeCurrency: {
                name: chainDetails.nativeCurrencyName,
                symbol: chainDetails.nativeCurrencySymbol, // 2-6 characters long
                decimals: chainDetails.nativeCurrencyDecimals,
              },
              blockExplorerUrls: [chainDetails.blockExplorerUrl],
            },
          ],
        })
        .then(() => {
          activate(MetaMask);
        })
        .catch((e) => {
          setActivatingConnector(undefined);
          getErrorNotificationMessage(e.message);
        });
      // await ethereum.enable();
    } catch (addError) {
      getErrorNotificationMessage(addError);
    }
  };

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !activatingConnector);

  function getErrorMessage(error) {
    setAuth({
      ...auth,
      wallet: {
        ...auth.wallet,
        loading: false,
        connectWalletStatus: false,
      },
    });

    setActivatingConnector(undefined);

    if (error instanceof NoEthereumProviderError) {
      getErrorNotificationMessage(
        "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
      );
    } else if (error instanceof UnsupportedChainIdError) {
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      getErrorNotificationMessage("User rejected the request");
    } else {
      console.log(error);
      // getErrorNotificationMessage(
      //   "An unknown error occurred. Check the console for more details"
      // );
    }
  }

  useEffect(() => {
    if (error) {
      getErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      chainDetails.netID !== chainId &&
      chainId &&
      connector instanceof InjectedConnector
    ) {
      deactivate();
    } else if (connector instanceof InjectedConnector) {
      activate(MetaMask);
    }
  }, [chainId]);

  const hanldeLogout = useCallback(() => {
    const accessToken = getCookie("accessToken");
    const tokenData = decodedToken(accessToken);
    localStorage.removeItem("inital_connect");
    setAuth({
      ...auth,
      wallet: {
        ...auth.wallet,
        loading: true,
        accounts: null,
        connectWalletStatus: false,
        ethBalance: null,
        BUSDTokenData: null,
        BUSDTokenBalance: null,
        BUSDXTokenData: null,
        BUSDXTokenBalance: null,
        logoutStatus: localStorage.getItem("inital_connect"),
        authStatus: null,
      },
      user: {
        userId: null,
        userUniqueId: null,
        authStatus: null,
      },
    });
    deactivate();
    if (accessToken) {
      if (tokenData.role === "creator") {
        dispatch(logoutStart());
      } else {
        dispatch(userLogoutStart());
      }
    } else {
      navigate("/");
      setTimeout(() => {
        getErrorNotificationMessage("session expired.please login again");
      }, 1000);
    }
  }, []);

  const getBalance = useCallback(async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    }

    const web3 = window.web3;

    const tokenData = Token.networks[chainId];
    let tokens = null;
    let BUSDToken = null;
    let BUSDTokenBalance = null;

    let BUSDXToken = null;
    let BUSDXTokenBalance = null;

    BUSDToken = new web3.eth.Contract(
      Token.abi,
      contractAddresses.tokenContractAddress
    );
    BUSDTokenBalance = await BUSDToken.methods
      .balanceOf(account)
      .call()
      .catch((e) => console.log(e));

    BUSDXToken = new web3.eth.Contract(
      Token.abi,
      contractAddresses.projectContractAddress
    );
    BUSDXTokenBalance = await BUSDXToken.methods
      .balanceOf(account)
      .call()
      .catch((e) => console.log(e));

    await library
      .getBalance(account)
      .then((balance) => {
        if (balance) {
          setAuth({
            ...auth,
            wallet: {
              ...auth.wallet,
              accounts: account,
              BUSDTokenData: BUSDToken,
              BUSDTokenBalance: formatEther(BUSDTokenBalance),
              BUSDXTokenData: BUSDXToken,
              BUSDXTokenBalance: formatEther(BUSDXTokenBalance),
              connectWalletStatus: false,
              ethBalance: formatEther(balance),
              logoutStatus: localStorage.getItem("inital_connect"),
            },
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setAuth({
          ...auth,
          wallet: {
            ...auth.wallet,
            ethBalance: null,
          },
        });
      });
  }, []);

  const saveAccountDetails = async () => {
    if (connector instanceof WalletConnectConnector) {
      // await walletConnectProvider.enable();
      window.web3 = new Web3(walletConnectProvider);
    }

    if (connector instanceof InjectedConnector) {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
    }
    const accessToken = getCookie("accessToken");
    const tokenDatas = decodedToken(accessToken);

    try {
      const web3 = window.web3;

      if (account.length > 0) {
        localStorage.setItem("inital_connect", true);
        const tokenData = Token.networks[chainId];
        let tokens = null;
        let BUSDToken = null;
        let BUSDTokenBalance = null;

        let BUSDXToken = null;
        let BUSDXTokenBalance = null;

        BUSDToken = new web3.eth.Contract(
          Token.abi,
          contractAddresses.tokenContractAddress
        );
        BUSDTokenBalance = await BUSDToken.methods
          .balanceOf(account)
          .call()
          .catch((e) => console.log(e));

        BUSDXToken = new web3.eth.Contract(
          Token.abi,
          contractAddresses.projectContractAddress
        );
        BUSDXTokenBalance = await BUSDXToken.methods
          .balanceOf(account)
          .call()
          .catch((e) => console.log(e));

        await library
          .getBalance(account)
          .then((balance) => {
            if (balance) {
              setAuth({
                ...auth,
                wallet: {
                  ...auth.wallet,
                  accounts: account,
                  BUSDTokenData: BUSDToken,
                  BUSDTokenBalance: formatEther(BUSDTokenBalance),
                  BUSDXTokenData: BUSDXToken,
                  BUSDXTokenBalance: formatEther(BUSDXTokenBalance),
                  connectWalletStatus: false,
                  ethBalance: formatEther(balance),
                  logoutStatus: localStorage.getItem("inital_connect"),
                  isWalletConnected: true,
                  authStatus: true,
                },
              });
            }
          })
          .catch((e) => {
            console.log(e);
            setAuth({
              ...auth,
              wallet: {
                ...auth.wallet,
                ethBalance: null,
              },
            });
          });

        if (
          !loginData.loading &&
          tokenDatas.role !== "creator" &&
          auth.wallet.accounts != account
        ) {
          if (accessToken === undefined) {
            dispatch(userLoginStart({ wallet_address: account }));
          } else {
            if (
              tokenDatas &&
              tokenDatas.role !== "creator" &&
              tokenDatas?.user_id !== profileState.data?.user_id
            ) {
              dispatch(fetchUserDetailsStart());
            }
          }
        }
      } else {
        hanldeLogout();
        getSuccessNotificationMessage("Signed out successfully");
      }
    } catch (error) {
      setAuth({
        ...auth,
        wallet: {
          ...auth.wallet,
          connectWalletStatus: false,
          authStatus: false,
          isWalletConnected: null,
        },
      });
    }
  };

  const getProviderSinger = useCallback((message_content) => {
    library
      .getSigner(account)
      .signMessage(message_content)
      .then((signature) => console.log(signature))
      .catch((error) => {
        getErrorNotificationMessage(error);
      });
  }, []);

  useEffect(() => {
    accessToken = getCookie("accessToken");
    tokenData = decodedToken(accessToken);

    if (account) {
      saveAccountDetails();
    } else {
      if (accessToken && tokenData.role !== "user") {
        handleWalletLogout();
      }
    }
  }, [account]);

  useEffect(() => {
    if (!loginData.loading) {
      if (Object.keys(loginData.data).length > 0) {
        setActivatingConnector(undefined);
        if (loginData.data.data.code == 101) {
          if (loginData.data.data.data.role === "user") {
            setAuth((prevAuth) => ({
              ...prevAuth,
              wallet: {
                ...prevAuth.wallet,
                loading: false,
                authStatus: true,
              },
              user: {
                ...prevAuth.user,
                userId: loginData.data.data.data.user_id,
                userUniqueId: loginData.data.data.data.user_unique_id,
                authStatus: true,
              },
            }));
          } else {
            setAuth((prevAuth) => ({
              ...prevAuth,
              user: {
                ...prevAuth.user,
                userId: loginData.data.data.data.user_id,
                userUniqueId: loginData.data.data.data.user_unique_id,
                authStatus: true,
              },
            }));
            dispatch(fetchCreatorProfileStart());
          }
        } else {
          setAuth({
            ...auth,
            wallet: {
              ...auth.wallet,
              loading: false,
              authStatus: false,
            },
            user: {
              ...auth.user,
              userId: null,
              userUniqueId: null,
              authStatus: false,
            },
          });
        }
      } else {
        setAuth({
          ...auth,
          wallet: {
            ...auth.wallet,
            loading: false,
            authStatus: false,
          },
          user: {
            ...auth.user,
            userId: null,
            userUniqueId: null,
            authStatus: false,
          },
        });
      }
    }
  }, [loginData.data]);

  const creatorLogin = useCallback(({ email, password }) => {
    dispatch(creatorLoginStart({ email: email, password: password }));
  }, []);

  const creatorRegister = useCallback(({ datas }) => {
    dispatch(creatorLoginStart(datas));
  });

  useEffect(() => {
    if (accessToken) {
      const tokenData = decodedToken(accessToken);
      if (tokenData.role === "user") {
        if (account) {
          saveAccountDetails();
        }
      } else {
      }
      if (tokenData.role === "creator") {
        dispatch(fetchCreatorProfileStart());
      }
    }
  }, []);

  useEffect(() => {
    accessToken = getCookie("accessToken");
    tokenData = decodedToken(accessToken);
    if (!profileState.loading && profileState.data && Object.keys(profileState.data).length > 0) {
      if (profileState.data.role === "user") {
        setAuth((prevAuth) => ({
          ...prevAuth,
          user: {
            ...prevAuth.user,
            userId: profileState.data.user_id,
            userUniqueId: profileState.data.user_unique_id,
            authStatus: true,
          },
        }));
      } else {
        setAuth((prevAuth) => ({
          ...prevAuth,
          user: {
            ...prevAuth.user,
            userId: profileState.data.user_id,
            userUniqueId: profileState.data.user_unique_id,
            authStatus: true,
          },
        }));
        if (localStorage.getItem("inital_connect" === "true")) {
          MetaMask.isAuthorized().then((isAuthorized) => {
            activate(MetaMask, undefined, true);
          });
        }
      }
    }
  }, [profileState]);

  const handleWalletLogout = useCallback(() => {
    localStorage.removeItem("inital_connect");
    setAuth({
      ...auth,
      wallet: {
        ...auth.wallet,
        loading: false,
        accounts: null,
        connectWalletStatus: false,
        ethBalance: null,
        BUSDTokenData: null,
        BUSDTokenBalance: null,
        BUSDXTokenData: null,
        BUSDXTokenBalance: null,
        logoutStatus: localStorage.getItem("inital_connect"),
        authStatus: false,
      },
    });
    deactivate();
  });

  return (
    <AuthContext.Provider
      value={{
        auth,
        context,
        handleConnector,
        loginConnectors,
        activatingConnector,
        hanldeLogout,
        supportedChains,
        getProviderSinger,
        getBalance,
        creatorLogin,
        creatorRegister,
        handleWalletLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;

import {
	FETCH_CREATOR_CONTRACT_LIST_START,
	FETCH_CREATOR_CONTRACT_LIST_SUCCESS,
	FETCH_CREATOR_CONTRACT_LIST_FAILURE,
	FETCH_CREATOR_SINGLE_CONTRACT_START,
	FETCH_CREATOR_SINGLE_CONTRACT_SUCCESS,
	FETCH_CREATOR_SINGLE_CONTRACT_FAILURE,
	CREATOR_CONTRACT_SAVE_START,
	CREATOR_CONTRACT_SAVE_SUCCESS,
	CREATOR_CONTRACT_SAVE_FAILURE,
	CREATOR_CONTRACT_DELETE_START,
	CREATOR_CONTRACT_DELETE_SUCCESS,
	CREATOR_CONTRACT_DELETE_FAILURE,
	UPDATE_CONTRACT_DEPLOY_ACCESS_START,
	UPDATE_CONTRACT_DEPLOY_ACCESS_SUCCESS,
	UPDATE_CONTRACT_DEPLOY_ACCESS_FAILURE,
	DEPLOY_CONTRACT_SAVE_START,
	DEPLOY_CONTRACT_SAVE_SUCCESS,
	DEPLOY_CONTRACT_SAVE_FAILURE,
	FETCH_WHITELISTED_WALLET_LIST_START,
	FETCH_WHITELISTED_WALLET_LIST_SUCCESS,
	FETCH_WHITELISTED_WALLET_LIST_FAILURE,
	SAVE_WHITELISTED_WALLET_START,
	SAVE_WHITELISTED_WALLET_SUCCESS,
	SAVE_WHITELISTED_WALLET_FAILURE,
	SET_MINT_FEE_UPDATE_START,
	SET_MINT_FEE_UPDATE_SUCCESS,
	SET_MINT_FEE_UPDATE_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
	contracts: {
		data: {},
		loading: false,
		error: false,
	},
	contract: {
		data: {},
		loading: false,
		error: false,
	},
	contractSave: {
		data: {},
		loading: true,
		error: false,
		success: {},
		buttonDisable: false,
		loadingButtonContent: null,
	},
	contractDelete: {
		data: {},
		loading: true,
		error: false,
		success: {},
		buttonDisable: false,
		loadingButtonContent: null,
	},
	updateDeployAccess: {
		data: {},
		loading: true,
		error: false,
		success: {},
		buttonDisable: false,
		loadingButtonContent: null,
	},
	deployAccessSave: {
		data: {},
		loading: true,
		error: false,
		success: {},
		buttonDisable: false,
		loadingButtonContent: null,
	},
	whitelistedWallets: {
		data: [],
		loading: true,
		error: false,
	},
	whitelistWalletSave: {
		data: {},
		loading: true,
		error: false,
		buttonDisable: false,
		loadingButtonContent: null,
	},
	setMintFeeUpdate: {
		data: {},
		loading: true,
		error: false,
		success: {},
		buttonDisable: false,
		loadingButtonContent: null,
	},
};

const creatorContractReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CREATOR_CONTRACT_LIST_START:
			return {
				...state,
				contracts: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_CREATOR_CONTRACT_LIST_SUCCESS:
			return {
				...state,
				contracts: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_CREATOR_CONTRACT_LIST_FAILURE:
			return {
				...state,
				contracts: {
					data: {},
					loading: false,
					error: action.error,
				},
			};
		case FETCH_CREATOR_SINGLE_CONTRACT_START:
			return {
				...state,
				contract: {
					data: {},
					loading: true,
					error: false,
				},
			};

		case FETCH_CREATOR_SINGLE_CONTRACT_SUCCESS:
			return {
				...state,
				contract: {
					data: action.data.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_CREATOR_SINGLE_CONTRACT_FAILURE:
			return {
				...state,
				contract: {
					data: state.data,
					loading: false,
					error: action.error,
				},
			};
		case CREATOR_CONTRACT_SAVE_START:
			return {
				...state,
				contractSave: {
					data: action.data,
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				},
			};
		case CREATOR_CONTRACT_SAVE_SUCCESS:
			return {
				...state,
				contractSave: {
					data: {},
					success: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case CREATOR_CONTRACT_SAVE_FAILURE:
			return {
				...state,
				contractSave: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case CREATOR_CONTRACT_DELETE_START:
			return {
				...state,
				contractDelete: {
					data: action.data,
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				},
			};
		case CREATOR_CONTRACT_DELETE_SUCCESS:
			return {
				...state,
				contractDelete: {
					data: {},
					success: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case CREATOR_CONTRACT_DELETE_FAILURE:
			return {
				...state,
				contractDelete: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case UPDATE_CONTRACT_DEPLOY_ACCESS_START:
			return {
				...state,
				updateDeployAccess: {
					data: action.data,
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				},
			};
		case UPDATE_CONTRACT_DEPLOY_ACCESS_SUCCESS:
			return {
				...state,
				updateDeployAccess: {
					data: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case UPDATE_CONTRACT_DEPLOY_ACCESS_FAILURE:
			return {
				...state,
				updateDeployAccess: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case DEPLOY_CONTRACT_SAVE_START:
			return {
				...state,
				deployAccessSave: {
					data: action.data,
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				},
			};
		case DEPLOY_CONTRACT_SAVE_SUCCESS:
			return {
				...state,
				deployAccessSave: {
					data: {},
					success: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case DEPLOY_CONTRACT_SAVE_FAILURE:
			return {
				...state,
				deployAccessSave: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case FETCH_WHITELISTED_WALLET_LIST_START:
			return {
				...state,
				whitelistedWallets: {
					data: [],
					loading: true,
					error: false,
				}
			};
		case FETCH_WHITELISTED_WALLET_LIST_SUCCESS:
			return {
				...state,
				whitelistedWallets: {
					data: action.data,
					loading: false,
					error: false,
				}
			};
		case FETCH_WHITELISTED_WALLET_LIST_FAILURE:
			return {
				...state,
				whitelistedWallets: {
					data: [],
					loading: true,
					error: action.error,
				}
			};
		case SAVE_WHITELISTED_WALLET_START:
			return {
				...state,
				whitelistWalletSave: {
					data: {},
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				}
			};
		case SAVE_WHITELISTED_WALLET_SUCCESS:
			return {
				...state,
				whitelistWalletSave: {
					data: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				}
			};
		case SAVE_WHITELISTED_WALLET_FAILURE:
			return {
				...state,
				whitelistWalletSave: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				}
			};
		case SET_MINT_FEE_UPDATE_START:
			return {
				...state,
				setMintFee: {
					data: action.data,
					loading: true,
					error: false,
					buttonDisable: true,
					loadingButtonContent: "Loading please wait",
				},
			};
		case SET_MINT_FEE_UPDATE_SUCCESS:
			return {
				...state,
				setMintFee: {
					data: {},
					success: action.data,
					loading: false,
					error: false,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		case SET_MINT_FEE_UPDATE_FAILURE:
			return {
				...state,
				setMintFee: {
					data: {},
					loading: true,
					error: action.error,
					buttonDisable: false,
					loadingButtonContent: null,
				},
			};
		default:
			return state;
	}
};

export default creatorContractReducer;

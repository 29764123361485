import { call, select, put, takeLatest, all } from "redux-saga/effects";

import {
    FETCH_USER_CONTRACT_LIST_START,
    FETCH_USER_SINGLE_CONTRACT_START
} from "../actions/ActionConstant";

import {
    fetchUserContractListFailure,
    fetchUserContractListSuccess,
    fetchUserSingleContractFailure,
    fetchUserSingleContractSuccess
} from "../actions/UserContractAction";

import api from "src/Environment";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
} from "src/components/ToastNotification";


function* userContractListAPI() {
    try {
        const response = yield api.getMethod("user/contracts_list");
        if (response.data.success) {
            yield put(fetchUserContractListSuccess(response.data.data));
        } else {
            yield put(fetchUserContractListFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserContractListFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

function* userSingleContractAPI(action) {
    try {
        const response = yield api.postMethod("user/contracts_view", action.data);
        if (response.data.success) {
            yield put(fetchUserSingleContractSuccess(response.data.data));
        } else {
            yield put(fetchUserSingleContractFailure(response.data.error));
            yield call(getErrorNotificationMessage, response.data.error);
        }
    } catch (error) {
        yield put(fetchUserSingleContractFailure(error));
        yield call(getErrorNotificationMessage, error.message);
    }
}

export default function* pageSaga() {
    yield all([
        yield takeLatest(FETCH_USER_CONTRACT_LIST_START, userContractListAPI),
        yield takeLatest(FETCH_USER_SINGLE_CONTRACT_START, userSingleContractAPI)
    ]);
}
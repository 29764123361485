import { call, select, put, takeLatest, all } from "redux-saga/effects";

import api from "src/Environment";
import {
  FETCH_WALLET_DETAILS_START,
  ADD_MONEY_VIA_BANK_START,
  ADD_MONEY_VIA_CARD_START,
  WALLET_HISTORY_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";
import {
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  addMoneyViaCardSuccess,
  addMoneyViaCardFailure,
  addMoneyViaBankSuccess,
  addMoneyViaBankFailure,
  walletHistorySuccess,
  walletHistoryFailure,
} from "../actions/WalletAction";
import { checkLogoutStatus } from "../actions/ErrorAction";

function* fetchWalletDetailsAPI() {
  try {
    const response = yield api.postMethod("wallets_index");
    if (response.data.success) {
      yield put(fetchWalletDetailsSuccess(response.data.data));
      // Do nothing
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(fetchWalletDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage,  response.data.error);
    }
  } catch (error) {
    yield put(fetchWalletDetailsFailure(error));
    yield call(getErrorNotificationMessage,  error.response.data.error);
  }
}

function* addMoneyViaCardAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_stripe",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaCardSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaCardFailure(response.data.error));
      yield call(getErrorNotificationMessage,  response.data.error);
    }
  } catch (error) {
    yield put(addMoneyViaCardFailure(error));
    yield call(getErrorNotificationMessage,  error.response.data.error);
  }
}

function* addMoneyViaBankAPI() {
  try {
    const inputData = yield select((state) => state.wallet.addMoneyInput.data);
    const response = yield api.postMethod(
      "wallets_add_money_by_bank_account",
      inputData
    );
    if (response.data.success) {
      yield put(addMoneyViaBankSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/wallet");
    } else {
      yield put(addMoneyViaBankFailure(response.data.error));
      yield call(getErrorNotificationMessage,  response.data.error);
    }
  } catch (error) {
    yield put(addMoneyViaBankFailure(error));
    yield call(getErrorNotificationMessage,  error.response.data.error);
  }
}

function* walletHistoryAPI() {
  try {
    const response = yield api.postMethod("wallets_history");
    if (response.data.success) {
      yield put(walletHistorySuccess(response.data.data));
      // Do nothing
    } else {
      yield put(checkLogoutStatus(response.data));
      yield put(walletHistoryFailure(response.data.error));
      yield call(getErrorNotificationMessage,  response.data.error);
    }
  } catch (error) {
    yield put(walletHistoryFailure(error));
    yield call(getErrorNotificationMessage,  error.response.data.error);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_WALLET_DETAILS_START, fetchWalletDetailsAPI),
    yield takeLatest(ADD_MONEY_VIA_BANK_START, addMoneyViaBankAPI),
    yield takeLatest(ADD_MONEY_VIA_CARD_START, addMoneyViaCardAPI),
    yield takeLatest(WALLET_HISTORY_START, walletHistoryAPI),
  ]);
}

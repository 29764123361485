import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import WalletReducer from "./WalletReducer";
import PageReducer from "./PageReducer";
import VerificationDocumentReducer from "./VerificationDocumentReducer";
import SubscriptionReducer from "./SubscriptionReducer";
import KycDocumentReducer from "./KycDocumentReducer";
import ProjectReducer from "./ProjectsReducer";
import FaqReducer from "./FaqReducer";
import StakeUnstakeReducer from "./StakeUnstakeReducer";
import TokenReducer from "./TokenReducer";
import NFTImagesReducer from "./NFTImagesReducer";
import CreatorReducer from "./CreatorReducer";
import CreatorContractReducer from "./CreatorContractReducer";
import NftReducer from './NftReducer';
import UserContractReducer from './UserContractReducer';

export default combineReducers({
  users: UserReducer,
  kycDocument: KycDocumentReducer,
  subscriptions: SubscriptionReducer,
  docs: VerificationDocumentReducer,
  wallet: WalletReducer,
  page: PageReducer,
  projectReducer: ProjectReducer,
  faq: FaqReducer,
  stakeUnstake: StakeUnstakeReducer,
  token: TokenReducer,
  nft: NftReducer,
  nftImages: NFTImagesReducer,
  creators: CreatorReducer,
  creatorContracts: CreatorContractReducer,
  userContracts: UserContractReducer,
});

// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  contract: <FolderIcon />,
  user: <PersonOutlineIcon />,
  dashboard: <DashboardIcon />,
  logout : <LogoutIcon />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: "/creator/dashboard", icon: ICONS.dashboard },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'Contract',
        path: "/dashboard",
        icon: ICONS.contract,
        children: [
          { title: 'Create contract', path : "/creator/contract/create" },
          { title: 'Contract List', path: "/creator/contract/list" },
        ],
      },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'account',
    items: [
      {
        title: 'Profile',
        path: "/creator/profile",
        icon: ICONS.user,
      },
      { title: 'Logout', path: "/auth/logout", icon: ICONS.logout },
    ],
  },
];

export default navConfig;

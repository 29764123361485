import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  userLoginSuccess,
  userLoginFailure,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
} from "../actions/UserAction";

import {
  updateCreatorDetailsSuccess,
  updateCreatorDetailsFailure,
  creatorChangePasswordSuccess,
  creatorChangePasswordFailure,
  logoutSuccess,
  logoutFailure,
  creatorDeleteAccountSuccess,
  creatorDeleteAccountFailure,
  fetchCreatorProfileStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  creatorRegisterSuccess,
  creatorRegisterFailure,
} from "../actions/CreatorAction";

import api from "src/Environment";
import {
  CREATOR_LOGIN_START,
  FETCH_CREATOR_PROFILE_START,
  UPDATE_CREATOR_DETAILS_START,
  CREATOR_CHANGE_PASSWORD_START,
  CREATOR_LOGOUT_START,
  CREATOR_DELETE_ACCOUNT_START,
  FETCH_CREATOR_DASHBOARD_START,
  CREATOR_REGISTER_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";

import Cookies from "universal-cookie";

function* creatorLoginAPI(action) {
  const cookies = new Cookies();
  try {
    const response = yield api.postMethod("creator/login", action.data);
    if (response.data.success) {
      yield put(userLoginSuccess(response.data));
      cookies.remove("accessToken", { sameSite: true, path: "/" });
      cookies.set("accessToken", response.data.data.jwt_token, {
        sameSite: true,
        path: "/",
      });
      // yield put(fetchCreatorProfileStart());
    } else {
      yield put(userLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("creator/profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateCreatorDetailsAPI(action) {
  const cookies = new Cookies();
  try {
    const response = yield api.postMethod(
      "creator/update_profile",
      action.data
    );

    if (response.data.success) {
      yield put(updateCreatorDetailsSuccess(response.data));
      yield put(fetchUserDetailsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateCreatorDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorChangePasswordAPI(action) {
  try {
    const response = yield api.postMethod(
      "creator/change_password",
      action.data
    );
    if (response.data.success) {
      yield put(creatorChangePasswordSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(creatorChangePasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(creatorChangePasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* logoutAPI() {
  const cookies = new Cookies();
  try {
    const response = yield api.postMethod("creator/logout");
    if (response.data.success) {
      yield put(logoutSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      cookies.remove("accessToken", { path: "/" });
      window.location.assign("/");
    } else {
      yield put(logoutFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(logoutFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorDeleteAccountAPI(action) {
  try {
    const response = yield api.postMethod(
      "creator/delete_account",
      action.data
    );

    if (response.data.success) {
      yield put(creatorDeleteAccountSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(creatorDeleteAccountFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* dashboardAPI(action) {
  try {
    const response = yield api.postMethod("creator/dashboard");
    if (response.data.success) {
      yield put(fetchDashboardSuccess(response.data.data));
    } else {
      yield put(fetchDashboardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDashboardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* creatorRegisterAPI(action) {
  try {
    const response = yield api.postMethod("creator/register_application_save", action.data);
    if (response.data.success) {
      yield put(creatorRegisterSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(creatorRegisterFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(CREATOR_LOGIN_START, creatorLoginAPI),
    yield takeLatest(FETCH_CREATOR_PROFILE_START, getUserDetailsAPI),
    yield takeLatest(UPDATE_CREATOR_DETAILS_START, updateCreatorDetailsAPI),
    yield takeLatest(CREATOR_CHANGE_PASSWORD_START, creatorChangePasswordAPI),
    yield takeLatest(CREATOR_LOGOUT_START, logoutAPI),
    yield takeLatest(CREATOR_DELETE_ACCOUNT_START, creatorDeleteAccountAPI),
    yield takeLatest(FETCH_CREATOR_DASHBOARD_START, dashboardAPI),
    yield takeLatest(CREATOR_REGISTER_START, creatorRegisterAPI),
  ]);
}

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsSuccess,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsFailure,
  notificationStatusUpdateSuccess,
  notificationStatusUpdateFailure,
  fetchPaymentsSuccess,
  fetchPaymentsFailure,
  modelEnrollFailure,
  contactUsSuccess,
  contactUsFailure,
  creatorLoginSuccess,
  creatorLoginFailure,
  userLogoutSuccess,
  userLogoutFailure,
  userLoginStart,
  fetchUserDetailsStart,
  fetchUserMintedContractsSuccess,
  fetchUserMintedContractsFailure,
  updateNftMintStatusSuccess,
  updateNftMintStatusFailure,
} from "../actions/UserAction";

import api from "src/Environment";
import {
  FETCH_USER_DETAILS_START,
  UPDATE_USER_DETAILS_START,
  LOGIN_START,
  NOTIFICATION_STATUS_UPDATE_START,
  FETCH_PAYMENTS_START,
  USERNAME_AVAILABILITY_START,
  CONTACT_US_START,
  USER_LOGOUT_START,
  USER_ACCOUNT_CHANGE_START,
  FETCH_USER_MINTED_CONTRACTS_START,
  UPDATE_NFT_MINT_STATUS_START
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";

import Cookies from "universal-cookie";

function* getUserDetailsAPI() {
  try {
    const response = yield api.postMethod("user/profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data));
    } else {
      yield put(fetchUserDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateUserDetailsAPI(action) {
  const cookies = new Cookies();
  try {
    // const userData = yield select((state) => state.users.profileInputData.data);
    const response = yield api.postMethod("user/update_profile", action.data);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      // setTimeout(() => {
      //   window.location.assign("/account/profile");
      // }, 1000);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(updateUserDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userLoginAPI() {
  try {
    const cookies = new Cookies();

    const userData = yield select((state) => state.users.loginInputData.data);

    const response = yield api.postMethod("user/login", userData);
    yield put(userLoginSuccess(response.data));
    if (response.data.success) {
      cookies.remove("accessToken", { sameSite: true, path: "/" });
      cookies.set("accessToken", response.data.data.jwt_token, { path: "/" });
      yield put(fetchUserDetailsStart());
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* notificationStatusUpdateAPI() {
  try {
    const userData = yield select(
      (state) => state.users.notificationUpdate.inputData
    );
    const response = yield api.postMethod(
      "notifications_status_update",
      userData
    );
    if (response.data.success) {
      yield put(notificationStatusUpdateSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(notificationStatusUpdateFailure(response.data.error));
    }
  } catch (error) {
    yield put(notificationStatusUpdateFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getPaymentsAPI() {
  try {
    const response = yield api.postMethod("payments_index");

    if (response.data.success) {
      yield put(fetchPaymentsSuccess(response.data));
    } else {
      yield put(fetchPaymentsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchPaymentsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userAvailabilityAPI() {
  try {
    const inputData = yield select(
      (state) => state.users.usernameAva.inputData
    );
    const response = yield api.postMethod("username_validation", inputData);
    if (response.data.success) {
    } else {
      yield put(modelEnrollFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(modelEnrollFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* contactUsAPI() {
  try {
    const inputData = yield select((state) => state.users.contactUs.inputData);
    const response = yield api.postMethod("contact_form_save", inputData);
    if (response.data.success) {
      yield put(contactUsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      window.location.assign("/");
    } else {
      yield put(contactUsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(contactUsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userLogoutAPI() {
  const cookies = new Cookies();
  try {
    const response = yield api.postMethod("user/logout");
    if (response.data.success) {
      yield put(userLogoutSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      cookies.remove("accessToken");
      window.location.assign("/");
    } else {
      yield put(userLogoutFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLogoutFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userAccountChangeAPI(action) {
  const cookies = new Cookies();
  try {
    const response = yield api.postMethod("user/logout");

    if (response.data.success) {
      yield put(userLogoutSuccess(response.data.data));
      cookies.remove("accessToken", { path: "/" });
      yield put(userLoginStart({ wallet_address: action.data.wallet_address }));
    } else {
      yield put(userLogoutFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLogoutFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchUserMintedContractsAPI(action) {
  try {
    const response = yield api.postMethod("user/minted_contracts", action.data);
    if (response.data.success) {
      yield put(fetchUserMintedContractsSuccess(response.data.data));
    } else {
      yield put(fetchUserMintedContractsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserMintedContractsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateNftMintStatusAPI(action) {
  try {
    const response = yield api.postMethod("user/nft_mint_status_update", action.data);
    if (response.data.success) {
      yield put(updateNftMintStatusSuccess(response.data.data));
    } else {
      yield put(updateNftMintStatusFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(updateNftMintStatusFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(
      NOTIFICATION_STATUS_UPDATE_START,
      notificationStatusUpdateAPI
    ),
    yield takeLatest(FETCH_PAYMENTS_START, getPaymentsAPI),
    yield takeLatest(USERNAME_AVAILABILITY_START, userAvailabilityAPI),
    yield takeLatest(CONTACT_US_START, contactUsAPI),
    yield takeLatest(USER_LOGOUT_START, userLogoutAPI),
    yield takeLatest(USER_ACCOUNT_CHANGE_START, userAccountChangeAPI),
    yield takeLatest(FETCH_USER_MINTED_CONTRACTS_START, fetchUserMintedContractsAPI),
    yield takeLatest(UPDATE_NFT_MINT_STATUS_START, updateNftMintStatusAPI),
  ]);
}

import {
    FETCH_USER_CONTRACT_LIST_FAILURE,
    FETCH_USER_CONTRACT_LIST_START, FETCH_USER_CONTRACT_LIST_SUCCESS, FETCH_USER_SINGLE_CONTRACT_FAILURE, FETCH_USER_SINGLE_CONTRACT_START, FETCH_USER_SINGLE_CONTRACT_SUCCESS,
} from "../actions/ActionConstant";


const initialState = {
    contracts: {
        data: {},
        loading: false,
        error: false,
    },
    contract: {
        data: {},
        loading: false,
        error: false,
    },
}

const userContractReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_CONTRACT_LIST_START:
            return {
                ...state,
                contracts: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_CONTRACT_LIST_SUCCESS:
            return {
                ...state,
                contracts: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_CONTRACT_LIST_FAILURE:
            return {
                ...state,
                contracts: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        case FETCH_USER_SINGLE_CONTRACT_START:
            return {
                ...state,
                contract: {
                    data: {},
                    loading: true,
                    error: false,
                },
            };
        case FETCH_USER_SINGLE_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: {
                    data: action.data,
                    loading: false,
                    error: false,
                },
            };
        case FETCH_USER_SINGLE_CONTRACT_FAILURE:
            return {
                ...state,
                contract: {
                    data: {},
                    loading: true,
                    error: action.error,
                },
            };
        default:
            return state;
    }
}

export default userContractReducer;
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import { ChartStyle } from "./components/chart";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import ThemeLocalization from "./components/ThemeLocalization";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import configuration from "react-global-configuration";
import { apiConstants } from "./Constant/constants";
import { Provider as ReduxProvider } from "react-redux";
// redux
import store from "./store";

import AuthProvider from "./contexts/auth/AuthProvider";

// ----------------------------------------------------------------------

export default function App() {
  const [configLoading, setConfigLoading] = useState(true);

  const [configError, setConfigError] = useState(null);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
      setConfigError(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
      setConfigError(true);
    }
  };

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                {/* <Settings /> */}
                <ScrollToTop />
                {configLoading ? null : configError ==
                  null ? null : configError ? (
                  <>
                    <Navigate to="500" replace />
                  </>
                ) : (
                  <ReduxProvider store={store}>
                    <AuthProvider>
                      <Router />
                    </AuthProvider>
                  </ReduxProvider>
                )}
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}

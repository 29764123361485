import useCookies from "./useCookies";
import { isValidToken, decodedToken } from "src/utils/jwt";
import { useEffect } from "react";
import useAuth from "./useAuth";
import { useState } from "react";

const useCurrentRole = () => {
  const { auth } = useAuth();
  const { getCookie } = useCookies();
  let accessToken;
  accessToken = getCookie("accessToken");
  const tokenData = decodedToken(accessToken);
  const [role, setRole] = useState(tokenData.role);

  useEffect(() => {
    accessToken = getCookie("accessToken");
    if (accessToken && isValidToken(accessToken)) {
      const tokenData = decodedToken(accessToken);
      setRole(tokenData.role);
    }
  }, [auth]);
  
  return role;
};

export default useCurrentRole;

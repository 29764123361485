import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_LIST_OF_NFT_START,
  FETCH_SINGLE_NFT_IMAGE_START,
  FETCH_SINGLE_NFT_PROPERTIES_START,
  FETCH_LIST_NFT_PROPERTIES_START,
  DELETE_SINGLE_NFT_START,
  UPLOAD_NFT_FILES_START,
  SEARCH_NFT_IMAGE_START,
  PAGINATE_LIST_OF_NFT_START,
  SORT_LIST_OF_NFT_START,
  UPDATE_NFT_DETAILS_START,
  ADD_NFT_IMAGE_START,
  USER_MINT_NFT_IMAGES_START,
  USER_UPDATE_NFT_IMAGE_MINT_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";

import {
  fetchListofNFTSuccess,
  fetchListofNFTFailure,
  fetchSingleNFTSuccess,
  fetchSingleNFTFailure,
  fetchSingleNFTPropertiesSuccess,
  fetchSingleNFTPropertiesFailure,
  fetchListNFTPropertiesSuccess,
  fetchListNFTPropertiesFailure,
  deleteSingleNFTSuccess,
  deleteSingleNFTFailure,
  uploadNFTFilesSuccess,
  uploadNFTFilesFailure,
  searchNFTImageSuccess,
  searchNFTImageFailure,
  sortListOfNFTSuccess,
  sortListOfNFTFailure,
  updateNFTDetailsSuccess,
  updateNFTDetailsFailure,
  addNFTImageSuccess,
  addNFTImageFailure,
  fetchNftImagesListAfterDelete,
  fetchSingleNFTStart,
  userMintNftImagesSuccess,
  userMintImagesFailure,
  userUpdateNftImageMintSuccess,
  userUpdateNftImageMintFailure,
} from "../actions/NFTImagesActions";

function* fetchListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "creator/nft_merge_images_list", action.data
    );
    if (response.data.success) {
      yield put(fetchListofNFTSuccess(response.data.data));
    } else {
      yield put(fetchListofNFTFailure(response.error));
      yield call(getErrorNotificationMessage, response.error);
    }
  } catch (error) {
    yield put(fetchListofNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "creator/nft_merge_image_view", action.data
    );
    if (response.data.success) {
      yield put(fetchSingleNFTSuccess(response.data.data));
    } else {
      yield put(fetchSingleNFTFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleNFTPropertiesAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_properties_view", action.data
    );
    if (response.data.success) {
      yield put(fetchSingleNFTPropertiesSuccess(response.data.data));
    } else {
      yield put(fetchSingleNFTPropertiesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleNFTPropertiesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchListOfNFTPropertiesAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_properties_index", action.data
    );
    if (response.data.success) {
      yield put(fetchListNFTPropertiesSuccess(response.data.data));
    } else {
      yield put(fetchListNFTPropertiesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchListNFTPropertiesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteSingleNFTStartAPI(action) {
  try {
    const response = yield api.postMethod(
      "creator/nft_merge_image_delete", action.data
    );
    if (response.data.success) {
      yield put(deleteSingleNFTSuccess(response.data.data));
      // window.location.assign("/list_of_nft")
      yield call(getSuccessNotificationMessage, response.data.message);
      // yield put(fetchNftImagesListAfterDelete(response.data.data))
    } else {
      yield put(deleteSingleNFTFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteSingleNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

// function* UploadNFTFilesAPI(action) {
//   try {
//     const response = yield api.postMethod(
//       "nft_import", action.data
//     );
//     if (response.data.success) {
//       yield put(uploadNFTFilesSuccess(response.data.data));
//       yield call(getSuccessNotificationMessage, response.data.message);
//       window.location.reload()
//     } else {
//       yield put(uploadNFTFilesFailure(response.data.error));
//       yield call(getErrorNotificationMessage, response.data.error);
//     }
//   } catch (error) {
//     yield put(uploadNFTFilesFailure(error));
//     yield call(getErrorNotificationMessage, error.message);
//   }
// }

function* searchNFTImageAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_image_search", action.data
    );
    if (response.data.success) {
      console.log(response.data.data)
      yield put(searchNFTImageSuccess(response.data.data));
    } else {
      yield put(searchNFTImageFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(searchNFTImageFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* paginateListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_images_list", action.data
    );
    if (response.data.success) {
      yield put(fetchListofNFTSuccess(response.data.data));
    } else {
      yield put(fetchListofNFTFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchListofNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* sortListOfNFTAPI(action) {
  try {
    const response = yield api.postMethod(
      "nft_merge_images_list", action.data
    );
    if (response.data.success) {
      yield put(sortListOfNFTSuccess(response.data.data));
    } else {
      yield put(sortListOfNFTFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(sortListOfNFTFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateNFTDetailsAPI(action) {
  try {
    const response = yield api.postMethod("creator/nft_single_merge_images_save", action.data);
    if (response.data.success) {
      yield put(updateNFTDetailsSuccess(response.data));

      yield call(getSuccessNotificationMessage, response.data.message);
      //window.location.reload("");
      yield put(fetchSingleNFTStart({ merge_image_unique_id: action.data.merge_image_unique_id }))
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(updateNFTDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateNFTDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* addNFTImageAPI(action) {
  try {
    const response = yield api.postMethod("creator/nft_single_merge_images_save", action.data);
    if (response.data.success) {
      yield put(addNFTImageSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(addNFTImageFailure(response.data.error));
    }
  } catch (error) {
    yield put(addNFTImageFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userMintNftImagesAPI(action) {
  try {
    const response = yield api.postMethod("user/mint_nft_images", action.data);
    if (response.data.success) {
      yield put(userMintNftImagesSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(userMintImagesFailure(response.data.error));
    }
  } catch (error) {
    yield put(addNFTImageFailure(error));
    yield call(userMintImagesFailure, error.message);
  }
}

function* userUpdateNftImageMintAPI(action) {
  try {
    const response = yield api.postMethod("user/nft_image_mint_update", action.data);
    if (response.data.success) {
      yield put(userUpdateNftImageMintSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(userUpdateNftImageMintFailure(response.data.error));
    }
  } catch (error) {
    yield put(addNFTImageFailure(error));
    yield call(userUpdateNftImageMintFailure, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_LIST_OF_NFT_START, fetchListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_NFT_IMAGE_START, fetchSingleNFTAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_SINGLE_NFT_PROPERTIES_START, fetchSingleNFTPropertiesAPI),
  ]);
  yield all([
    yield takeLatest(FETCH_LIST_NFT_PROPERTIES_START, fetchListOfNFTPropertiesAPI),
  ]);
  yield all([
    yield takeLatest(DELETE_SINGLE_NFT_START, deleteSingleNFTStartAPI),
  ]);
  // yield all([
  //   yield takeLatest(UPLOAD_NFT_FILES_START, UploadNFTFilesAPI),
  // ]);
  yield all([
    yield takeLatest(SEARCH_NFT_IMAGE_START, searchNFTImageAPI),
  ]);
  yield all([
    yield takeLatest(PAGINATE_LIST_OF_NFT_START, paginateListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(SORT_LIST_OF_NFT_START, sortListOfNFTAPI),
  ]);
  yield all([
    yield takeLatest(UPDATE_NFT_DETAILS_START, updateNFTDetailsAPI),
  ]);
  yield all([
    yield takeLatest(ADD_NFT_IMAGE_START, addNFTImageAPI),
  ]);
  yield all([
    yield takeLatest(USER_MINT_NFT_IMAGES_START, userMintNftImagesAPI),
  ]);
  yield all([
    yield takeLatest(USER_UPDATE_NFT_IMAGE_MINT_START, userUpdateNftImageMintAPI),
  ]);
}

import { call, put, takeLatest, all } from "redux-saga/effects";
import api from "src/Environment";
import { FETCH_FAQ_START } from "../actions/ActionConstant";
import {
  getErrorNotificationMessage,
} from "src/components/ToastNotification";
import {
  fetchFaqSuccess,
  fetchFaqFailure,
} from "../actions/FaqAction";


function* fetchFaqAPI(action) {
  try {
    const response = yield api.getMethod("user/faqs_index", action.data);
    if (response.data.success) {
      yield put(fetchFaqSuccess(response.data.data));
    } else {
      yield put(fetchFaqFailure(response.data.error));
      yield call(getErrorNotificationMessage , response.data.error)
    }
  } catch (error) {
    yield put(fetchFaqFailure(error));
    yield call(getErrorNotificationMessage , error.message)
  }
}


export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_FAQ_START, fetchFaqAPI)]);
}

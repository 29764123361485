// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const MyAvatar = ({ ...other }) => {
  const {user} = other;
  return (
    <Avatar
      src={user.data?.picture}
      alt={user.data?.name}
      color={user.data?.picture ? 'default' : createAvatar(user.data?.name).color}
      {...other}
    >
      {createAvatar(user.data?.name).name}
    </Avatar>
  );
}

const mapStateToProps = (state) => ({
  user : state.users.profile
})

export default connect(mapStateToProps , null)(MyAvatar)

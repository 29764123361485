import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import WalletConnectProvider from "@walletconnect/web3-provider";

import Web3 from 'web3';


export const MetaMask = new InjectedConnector({ supportedChainIds : [97]})

export const walletConnectProvider = new WalletConnectProvider({
  rpc: {
    56: "https://bsc-dataseed1.ninicoin.io",
  },
  chainId: 56
});

// export const walletconnect = new WalletConnectConnector({
//   rpc: { 1: process.env.RPC_URL_4 },
//   qrcode: true,
//   bridge: "https://bridge.walletconnect.org",
//   pollingInterval: POLLING_INTERVAL
// })


//velas mainnet

export const walletconnect = new WalletConnectConnector({
  rpc: {
      56: "https://bsc-dataseed1.ninicoin.io",
  },
  chainId : 56 ,
  qrcode: true,
});

// walletconnect.getProvider().then(c => console.log({ c })).catch(console.log)
// export const customWeb3Provider = walletconnect.getProvider();
export const customWeb3Provider = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.ninicoin.io'));

// export const provider = new WalletConnectProvider({
//   rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545"
// });
// walletconnect.networkId = 56;
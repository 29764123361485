import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  Button,
  Switch,
  Typography,
} from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
import AccountPopover from "./AccountPopover";
import useSettings from "src/hooks/useSettings";
import { useState, useCallback, useEffect } from "react";
import ConnectWalletModal from "src/modals/ConnectWalletModal";
import useAuth from "src/hooks/useAuth";
import AccountModal from "src/modals/AccountModal";
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.grey[600],
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.grey[500],
    borderRadius: 20 / 2,
  },
}));

const AccountModalButton = styled(Button)(({ theme }) => ({
  borderRadius: "5px",
  padding: "0.2rem 0.4rem",
}));

const AccountBalanceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const AccountWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: alpha(theme.palette.grey[500], 0.25),
  padding: "0.25rem 0.5rem",
  borderRadius: theme.spacing(1),
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const { themeMode, onChangeMode } = useSettings();

  const { auth } = useAuth();

  const [connectWalletModalStatus, setConnectWalletModalStatus] =
    useState(false);

  const [accountModalStatus, setAccountModalStatus] = useState(false);

  const hanldeConnectWalletModalOpen = () => {
    setConnectWalletModalStatus(true);
  };

  const hanldeConnectWalletModalClose = useCallback(() => {
    setConnectWalletModalStatus(false);
  }, []);

  const hanldeAccountModalOpen = () => {
    setAccountModalStatus(true);
  };

  const hanldeAccountModalClose = useCallback(() => {
    setAccountModalStatus(false);
  }, []);

  useEffect(() => {
    if (auth.wallet.authStatus) {
      hanldeConnectWalletModalClose();
    }
  }, [auth.wallet.authStatus]);

  return (
    <>
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
      >
        <Toolbar
          sx={{
            minHeight: "100% !important",
            px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {!isDesktop && (
            <IconButtonAnimate
              onClick={onOpenSidebar}
              sx={{ mr: 1, color: "text.primary" }}
            >
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 1, sm: 2 }}>
            <MaterialUISwitch
              sx={{ m: 1 }}
              value={themeMode === "light" ? "dark" : "light"}
              checked={themeMode === "dark"}
              onChange={onChangeMode}
            />
            {auth.wallet.authStatus ? (
              <>
                <AccountWrapper>
                  <AccountBalanceText variant="subtitle1">
                    {Number(auth.wallet.BUSDXTokenBalance).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    {auth.wallet.balanceTokens[1].name}
                  </AccountBalanceText>
                  <AccountModalButton
                    variant="contained"
                    onClick={hanldeAccountModalOpen}
                  >
                    {auth.wallet.accounts.substr(0, 4)}...
                    {auth.wallet.accounts.substr(
                      auth.wallet.accounts.length - 4
                    )}
                  </AccountModalButton>
                </AccountWrapper>
              </>
            ) : (
              <Button
                variant="contained"
                onClick={() => hanldeConnectWalletModalOpen()}
              >
                Connect Wallet
              </Button>
            )}
            <AccountPopover />
          </Stack>
        </Toolbar>
      </RootStyle>
      <ConnectWalletModal
        open={connectWalletModalStatus}
        handleClose={hanldeConnectWalletModalClose}
      />
      <AccountModal
        open={accountModalStatus}
        handleClose={hanldeAccountModalClose}
      />
    </>
  );
}

import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "src/Environment";
import { FETCH_SINGLE_PAGE_START } from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";
import {
  fetchSinglePageSuccess,
  fetchSinglePageFailure,
} from "../actions/PageAction";

function* fetchSinglePage() {
  try {
    const inputData = yield select((state) => state.page.pageData.inputData);
    const response = yield api.postMethod("user/static_pages_view", inputData);
    if (response.data.success) {
      yield put(fetchSinglePageSuccess(response.data.data));
    } else {
      yield put(fetchSinglePageFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSinglePageFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SINGLE_PAGE_START, fetchSinglePage)]);
}

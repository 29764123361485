// components
import Iconify from '../../components/Iconify';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <HomeOutlinedIcon sx={{...ICON_SIZE}}/>,
    path: '/',
  },
  // {
  //   title: 'Add Project',
  //   icon: <AddCircleOutlineIcon sx={{...ICON_SIZE}}/>,
  //   path: '/add_project',
  // },
  {
    title: 'Contracts',
    icon: <GridViewOutlinedIcon sx={{...ICON_SIZE}}/>,
    path: '/contracts',
  },
  // {
  //   title: 'Staking',
  //   icon: <PointOfSaleOutlinedIcon sx={{...ICON_SIZE}} />,
  //   path: '/staking',
  // },
];

export default menuConfig;

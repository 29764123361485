import { call, put, takeLatest, all } from "redux-saga/effects";
import api from "src/Environment";
import {
  ADD_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_LIST_START,
  GET_KYC_DOCUMENT_START,
  DELETE_KYC_DOCUMENT_START
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "src/components/ToastNotification";
import {
  addKycDocumentSuccess,
  addKycDocumentFailure,
  getKycDocumentSuccess,
  getKycDocumentFailure,
  getKYCDocumentsListSuccess,
  getKYCDocumentsListFailure,
  getKYCDocumentsListStart,
  deleteKYCDocumentSuccess,
  deleteKycDocumentFailure
} from "../actions/KycDocumentAction";

function* addKycDocumentAPI(action) {
  const response = yield api.postMethod("documents_save", action.data);
  yield put(addKycDocumentSuccess(response.data.data));
  if (response.data.success) {
    yield call(getSuccessNotificationMessage, response.data.message);
    yield put(getKYCDocumentsListStart())
  } else {
    yield put(addKycDocumentFailure(response.data.error));
    yield call(getErrorNotificationMessage, response.data.error);
  }
}

function* getKycDocumentAPI() {
  try {
    const response = yield api.postMethod("documents_list");
    yield put(getKycDocumentSuccess(response.data.data));
    if (response.data.success) {
      // Do nothing..
    } else {
      yield put(getKycDocumentFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getKycDocumentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* getKycDocumentListAPI() {
  try {
    const response = yield api.postMethod("documents_list");
    if (response.data.success) {
      yield put(getKYCDocumentsListSuccess(response.data.data));
    } else {
      yield put(getKYCDocumentsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getKYCDocumentsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteKYCDocumentAPI(action) {
  try {
    const response = yield api.postMethod("documents_delete" , action.data);
    if (response.data.success) {
      console.log(response.data.data)
      yield put(deleteKYCDocumentSuccess(response.data.data));
      yield put(getKYCDocumentsListStart())
    } else {
      yield put(deleteKycDocumentFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteKycDocumentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(ADD_KYC_DOCUMENT_START, addKycDocumentAPI)]);
  yield all([yield takeLatest(GET_KYC_DOCUMENT_START, getKycDocumentAPI)]);
  yield all([yield takeLatest(GET_KYC_DOCUMENT_LIST_START, getKycDocumentListAPI)]);
  yield all([yield takeLatest(DELETE_KYC_DOCUMENT_START, deleteKYCDocumentAPI)]);
}
